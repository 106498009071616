import { Container } from '@mui/material';

import { Box } from '@mui/system';
// components
// hooks
// routes

import { useLazyQuery, useMutation } from '@apollo/client';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, rectSortingStrategy } from '@dnd-kit/sortable';
import { APP_SECTIONS, UPDATE_APP_SECTION } from '_apis_/queries/appSections';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import LoadingComponent from 'components/LoadingComponent';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { PATH_DASHBOARD } from 'routes/paths';
import AppSectionForm from './components/AppSectionForm';
import AppSectionItem from './components/AppSectionItem';
import { removeTypenameKey } from 'components/_dashboard/promotion/tools';

export const ItemTypes = {
  BOX: 'box'
};

export default function AppSections() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [allSection, setAllSection] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [dragEnd, setDragEnd] = useState(false);

  const [appSectionList] = useLazyQuery(APP_SECTIONS, {
    onCompleted(response) {
      if (response?.appSections) {
        const temp = response?.appSections.sort(function (a: any, b: any) {
          if (a.sort < b.sort) {
            return -1;
          }
          if (a.sort > b.sort) {
            return 1;
          }
          return 0;
        });
        removeTypenameKey(temp);
        setAllSection(temp);
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError(error) {
      setLoading(false);
    }
  });

  useEffect(() => {
    appSectionList();
  }, [appSectionList, loading, allSection]);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 0.1
    }
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor, pointerSensor);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      setLoading(true);
      setAllSection((items: any) => {
        const oldIndex = items.findIndex(({ id }: any) => id === active.id);
        const newIndex = items.findIndex(({ id }: any) => id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
      setDragEnd(!dragEnd);
    }
  };

  const [updateAppSection] = useMutation(UPDATE_APP_SECTION, {
    onCompleted(response) {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError(error) {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (allSection.length) {
      allSection.forEach((section: any, index: number) => {
        let newSection: any = {};
        Object.keys(section).forEach((item) => {
          if (
            item !== 'id' &&
            item !== 'items' &&
            item !== 'image' &&
            item !== 'merchants' &&
            item !== '__typename'
          ) {
            newSection[item] = section[item];
          }
        });
        const merchantIds =
          section.merchants?.map((item: any) => {
            return item.merchantId;
          }) || [];

        const variables = {
          sectionId: section.id,
          section: {
            ...newSection,
            sort: index,
            merchantIds: merchantIds
          }
        };
        updateAppSection({ variables });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragEnd]);

  const sort = allSection?.length > 0 ? allSection[allSection?.length - 1]?.sort + 1 : 0;

  return (
    <Page title={t('App Sections')}>
      <DndProvider backend={HTML5Backend}>
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={t('App Sections')}
            links={[
              { name: t('Dash Board'), href: PATH_DASHBOARD.root },
              { name: t('App Sections'), href: PATH_DASHBOARD.appSections.list },
              { name: t('List') }
            ]}
          />

          <AppSectionForm edit={false} setLoading={setLoading} sort={sort} />

          <Box
            display="flex"
            justifyContent="space-between"
            width="60%"
            style={{
              padding: '10px',
              border: '1px dashed black',
              borderRadius: '10px',
              marginTop: '20px'
            }}
          >
            <Box
              style={{
                width: '100%',
                minHeight: '600px',
                maxHeight: '600px',
                overflowY: 'scroll'
              }}
            >
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement, restrictToWindowEdges]}
              >
                <SortableContext
                  items={allSection?.map(({ id }: any) => id)}
                  strategy={rectSortingStrategy}
                >
                  {!loading ? (
                    allSection?.map((section: any, index: number) => (
                      <AppSectionItem
                        key={section.id}
                        section={section}
                        setLoading={setLoading}
                        index={index}
                      />
                    ))
                  ) : (
                    <LoadingComponent />
                  )}
                </SortableContext>
              </DndContext>
            </Box>
          </Box>
        </Container>
      </DndProvider>
    </Page>
  );
}
