import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { Checkbox, FormControlLabel } from '@mui/material';
import { CSSProperties, SyntheticEvent } from 'react';

interface promoApplyDiscountToOptionsProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoApplyDiscountToOptions: React.FC<promoApplyDiscountToOptionsProps> = (props) => {
  const { formik } = props;
  const { applyOptionDiscount } = formik.values;
  const handleApplyOptionDiscountOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    formik.setFieldValue('applyOptionDiscount', checked);
  };
  return (
    <>
      <FormControlLabel
        value={applyOptionDiscount}
        checked={applyOptionDiscount}
        onChange={handleApplyOptionDiscountOnChange}
        control={<Checkbox />}
        label="Apply discount to options"
        labelPlacement="end"
      />
    </>
  );
};
