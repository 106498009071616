import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface promoUpToAmountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoOfferUpToAmount: React.FC<promoUpToAmountProps> = (props) => {
  const { formik, sx = {} } = props;
  const offerVoucherSettings = formik.values.offerVoucherSettings;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleUpToAmountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // make sure the value is a number
    if (isNaN(Number(e.target.value))) {
      return;
    }
    const numberValue = Number(e.target.value);
    formik.setFieldValue('offerVoucherSettings.upToAmount', numberValue);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>
          {t('Up To Amount')}
          <Tooltip title={t('The maximum amount that can be discounted by this offer.')}>
            <IconButton>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Label>
        <TextField
          sx={{ marginBottom: '24px' }}
          placeholder={t('Up To Amount?')}
          type="number"
          name="upToAmount"
          value={offerVoucherSettings?.upToAmount}
          onChange={handleUpToAmountOnChange}
        />
      </FormControl>
    </>
  );
};
