import { FormikProps, getIn } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoExpiryDaysProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}

export const PromoExpiryDays: React.FC<promoExpiryDaysProps> = (props) => {
  const { formik, sx = {} } = props;
  const { values, touched, errors } = formik;
  const { loyaltySettings } = values;

  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleExpiryDaysOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // number
    // nan check
    if (isNaN(Number(value))) {
      return;
    }
    const numValue = Number(value);
    formik.setFieldValue('loyaltySettings.expiryDays', numValue);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Points expire in 365 days.')}
          type="number"
          name="loyaltySettings.expiryDays"
          value={loyaltySettings?.expiryDays || ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'Days'}</InputAdornment>
          }}
          onChange={handleExpiryDaysOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            getIn(touched, 'loyaltySettings.expiryDays') &&
              getIn(errors, 'loyaltySettings.expiryDays')
          )}
          helperText={
            getIn(touched, 'loyaltySettings.expiryDays') &&
            getIn(errors, 'loyaltySettings.expiryDays')
          }
        />
      </FormControl>
    </>
  );
};
