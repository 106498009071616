import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, Grid, Stack } from '@mui/material';
import { Label } from '../styles';
import { PromotionTagsSelector } from '../create/tabs/tagsSelect';
import { useTranslation } from 'react-i18next';
import ItemButton from './itemButton';

interface promoOfferItemGetTagsProps {
  formik: FormikProps<Promotion>;
}

export const PromoOfferItemGetTags: React.FC<promoOfferItemGetTagsProps> = (props) => {
  const { formik } = props;
  const includedGetItemsPromoTags: string[] = formik.values.includedGetItemsPromoTags || [];
  const { t } = useTranslation();

  const handleIncludedGetItemsPromoTagsOnClick = (tag: string) => {
    const index = includedGetItemsPromoTags.indexOf(tag);
    if (index > -1) {
      includedGetItemsPromoTags.splice(index, 1);
    } else {
      includedGetItemsPromoTags.push(tag);
    }
    formik.setFieldValue('includedGetItemsPromoTags', includedGetItemsPromoTags);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%' }}>
          <FormControl sx={{ width: '100%' }}>
            <Label>{t('Applicable Items')}</Label>
            <PromotionTagsSelector fieldString="includedGetItemsPromoTags" formik={formik} />
          </FormControl>
        </FormControl>
      </Grid>
      {includedGetItemsPromoTags.length > 0 && (
        <Grid item xs={12} md={6}>
          <FormControl sx={{ width: '100%' }}>
            <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
              {includedGetItemsPromoTags.map((tag, index) => (
                <div key={index}>
                  <ItemButton
                    onClick={() => handleIncludedGetItemsPromoTagsOnClick(tag)}
                    content={tag}
                  />
                </div>
              ))}
            </Stack>
          </FormControl>
        </Grid>
      )}
    </>
  );
};
