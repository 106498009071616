import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoEntryLimitPerUserProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoEntryLimitPerUser: React.FC<promoEntryLimitPerUserProps> = (props) => {
  const { formik, sx = {} } = props;
  const { entryLimitPerUser } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleEntryLimitPerUserOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('entryLimitPerUser', value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Maximum Number Of Entries Allowed Per User')}
          type="number"
          name="entryLimitPerUser"
          value={entryLimitPerUser}
          onChange={handleEntryLimitPerUserOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.entryLimitPerUser && formik.errors.entryLimitPerUser)}
          helperText={
            formik.touched.entryLimitPerUser &&
            formik.errors.entryLimitPerUser &&
            t(`${formik.errors.entryLimitPerUser}`)
          }
        />
      </FormControl>
    </>
  );
};
