import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import ConfirmDialog from 'components/commonComponents/ConfirmDialog';
import { useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { MIconButton } from 'components/@material-extend';
import { useMutation } from '@apollo/client';
import { APP_SECTIONS, DELETE_APP_SECTION, PUBLISH_APP_SECTION } from '_apis_/queries/appSections';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useTranslation } from 'react-i18next';
import AppSectionForm from './AppSectionForm';
import { ContentStatus } from '@type/promotion-management';
import PublishedWithChangesSharpIcon from '@mui/icons-material/PublishedWithChangesSharp';
import { convertCamelCase } from 'utils/convertCamelCase';

interface IProps {
  section: any;
  setLoading: any;
  index: number;
}

export const ItemTypes = {
  BOX: 'box'
};

export default function AppSectionItem({ section, setLoading, index }: IProps) {
  const { t } = useTranslation();

  const [isDelete, setIsDelete] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [deleteAppSection] = useMutation(DELETE_APP_SECTION, {
    onCompleted(response) {
      setLoading(false);
      enqueueSnackbar(t('Delete Success'), {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    },
    refetchQueries: [{ query: APP_SECTIONS }],
    onError(error) {
      setLoading(false);
      enqueueSnackbar(t('Delete Fail'), {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  const [publishSection] = useMutation(PUBLISH_APP_SECTION, {
    onCompleted(response) {
      setLoading(false);
      enqueueSnackbar(t('Publish Success'), {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    },
    refetchQueries: [{ query: APP_SECTIONS }],
    onError(error) {
      setLoading(false);
      enqueueSnackbar(t('Publish Fail'), {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  const handleDeleteItem = (section: any) => {
    const variables = {
      sectionId: section.id
    };

    deleteAppSection({ variables });
    setLoading(true);
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: section.id
  });

  const handlePublish = (section: any) => {
    const variables = {
      sectionId: section.id
    };
    publishSection({ variables });
    setLoading(true);
  };

  return (
    <Paper
      variant="outlined"
      color="primary"
      sx={{
        mt: index === 0 ? '0px' : '24px',
        position: 'relative',
        padding: '16px',
        cursor: 'move',
        transform: `${CSS.Transform.toString(transform)}`,
        transition
      }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <Box
        sx={{
          cursor: 'move',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Title')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.title}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Content Type')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.contentType}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('View Type')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{convertCamelCase(section.viewType)}</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Typography sx={{ flex: 4 }}>{`${t('Status')} :`}</Typography>
            <Typography sx={{ flex: 6 }}>{section.status}</Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <AppSectionForm edit={true} section={section} setLoading={setLoading} />
          <Button
            sx={{ padding: 0, minWidth: '40px', height: '30px' }}
            onClick={() => setIsDelete(true)}
          >
            <DeleteIcon color="secondary" fontSize="small" />
          </Button>
          <Button
            disabled={section.status === ContentStatus.PUBLISHED}
            sx={{ padding: 0, minWidth: '40px', height: '30px' }}
            onClick={() => setIsPublished(true)}
          >
            <PublishedWithChangesSharpIcon
              color={section.status === ContentStatus.PUBLISHED ? 'disabled' : 'secondary'}
              fontSize="small"
            />
          </Button>
        </Box>
        <ConfirmDialog
          title={t('Delete')}
          message={`${t('Are you sure for delete section')} ${section.contentType}`}
          open={isDelete}
          close={() => setIsDelete(false)}
          action={() => handleDeleteItem(section)}
        />
        <ConfirmDialog
          title={t('Publish')}
          message={`${t('Are you sure for publish section')} ${section.contentType}`}
          open={isPublished}
          close={() => setIsPublished(false)}
          action={() => handlePublish(section)}
        />
      </Box>
    </Paper>
  );
}
