import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoDescriptionProps {
  formik: FormikProps<Promotion>;
  isOffer?: boolean;
  sx?: CSSProperties;
}
export const PromoDescription: React.FC<promoDescriptionProps> = (props) => {
  const { formik, isOffer, sx = {} } = props;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const getValue = () => {
    if (isOffer) {
      return formik.values.offerVoucherSettings?.offerDescription || '';
    }
    return formik.values.description;
  };
  const getFieldName = () => {
    if (isOffer) {
      return 'offerVoucherSettings.offerDescription';
    }
    return 'description';
  };

  const handleDescriptionOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isOffer) {
      formik.setFieldValue('offerVoucherSettings.offerDescription', e.target.value);
      return;
    }
    formik.setFieldValue('description', e.target.value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Description')}</Label>
        <TextField
          placeholder={t('Please enter the description of the voucher')}
          type="text"
          multiline={true}
          name={getFieldName()}
          value={getValue()}
          onChange={handleDescriptionOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.description && formik.errors.description)}
          helperText={
            formik.touched.description &&
            formik.errors.description &&
            t(`${formik.errors.description}`)
          }
        />
      </FormControl>
    </>
  );
};
