import { useLazyQuery, useMutation } from '@apollo/client';
import searchFill from '@iconify/icons-eva/search-fill';
import clear from '@iconify/icons-ic/clear';
import { Icon } from '@iconify/react';
import EditIcon from '@mui/icons-material/Edit';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import {
  AppContentLocation,
  AppContentType,
  AppViewType,
  ContentStatus
} from '@type/promotion-management';
import LoadingComponent from 'components/LoadingComponent';
import Scrollbar from 'components/Scrollbar';
import { PromotionListHead as TableHead } from 'components/_dashboard/promotion/list';
import { Form, FormikProvider, useFormik } from 'formik';
import TextNoItem from 'pages/dashboard/appSections/components/TextNoItem';
import { SearchStyle } from 'pages/dashboard/merchants/MerchantList';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertCamelCase } from 'utils/convertCamelCase';
import { applySortFilter, getComparator } from 'utils/functions';
import * as Yup from 'yup';
import { CREATE_APP_SECTION, UPDATE_APP_SECTION } from '_apis_/queries/appSections';
import { GET_ALL_MERCHANTS } from '_apis_/queries/merchant';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { GeolocationInput } from '__generated__/globalTypes';

interface IProps {
  edit: boolean;
  section?: any;
  setLoading: any;
  sort?: number;
}

interface Section {
  contentType: string;
  viewType: string;
  sort: number | undefined;
  location: string;
  title: string;
  description: string;
  status: string;
  merchantIds?: string[];
  radiumInKm?: number;
  coordinate?: GeolocationInput;
}

const TABLE_HEAD = [
  { id: '', label: '', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false }
];

function a11yProps(index: any) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`
  };
}

export default function AppSectionForm({ edit, section, setLoading, sort }: IProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [listAll, setListAll] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [listSelected, setListSelected] = useState<any[]>(section?.merchants || []);
  const [filteredList, setFilteredList] = useState(listAll || []);
  const [valueTab, setValueTab] = useState(0);
  const [error, setError] = useState(false);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const [updateAppSection] = useMutation(UPDATE_APP_SECTION, {
    onCompleted(response) {
      setLoading(false);
      setIsSubmitting(false);
      showSnackbar(t('Update Success'), 'success');
      setOpen(false);
      resetForm();
    },
    fetchPolicy: 'network-only',
    onError(error) {
      setLoading(false);
      setIsSubmitting(false);
      showSnackbar(t('Update Fail'), 'error');
    }
  });

  const [createAppSection] = useMutation(CREATE_APP_SECTION, {
    onCompleted(response) {
      setLoading(false);
      setIsSubmitting(false);
      showSnackbar(t('Create Success'), 'success');
      setOpen(false);
      resetForm();
    },
    fetchPolicy: 'network-only',
    onError(error) {
      setLoading(false);
      setIsSubmitting(false);
      showSnackbar(t('Create Fail'), 'error');
    }
  });

  const [queryGetAllMerchants] = useLazyQuery(GET_ALL_MERCHANTS, {
    onCompleted(response) {
      setIsLoading(false);
      setListAll(response?.getAllMerchants);
    },
    onError(error) {
      setIsLoading(false);
    },
    fetchPolicy: 'network-only'
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
    setError(false);
  };

  const getCoordinate = (section: any) => {
    // if section.coordinate is null, return null
    if (!section?.coordinate) {
      return {
        latitude: '',
        longitude: ''
      };
    }
    // if section.coordinate is not null, return coordinate object with latitude and longitude
    return {
      latitude: section.coordinate.latitude,
      longitude: section.coordinate.longitude
    };
  };

  const handleClick = (item: any) => {
    const index = listSelected.findIndex((itemSelected: any) => {
      return itemSelected.merchantId === item?.merchantId;
    });
    let newSelected: string[] = [];
    if (index === -1) {
      if (listSelected.length < 4) {
        newSelected = newSelected.concat(listSelected, item);
      } else {
        newSelected = listSelected;
      }
    } else if (index === 0) {
      newSelected = newSelected.concat(listSelected.slice(1));
    } else if (index === listSelected.length - 1) {
      newSelected = newSelected.concat(listSelected.slice(0, -1));
    } else if (index > 0) {
      newSelected = newSelected.concat(listSelected.slice(0, index), listSelected.slice(index + 1));
    }
    setListSelected(newSelected);
  };

  useEffect(() => {
    setIsLoading(true);
    queryGetAllMerchants();
  }, [queryGetAllMerchants]);

  useEffect(() => {
    setSearchValue('');
    setValueTab(0);
    if (section?.merchants) {
      setListSelected(section.merchants);
    } else {
      setListSelected([]);
    }
  }, [open, section]);

  useEffect(() => {
    const temp = applySortFilter(listAll, getComparator(order, orderBy), '');
    const searched = temp.filter((merchant: any) => {
      return (
        merchant?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        merchant?.type?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredList(searched);
  }, [searchValue, listAll, order, orderBy]);

  const createSection = (
    contentType: string,
    viewType: string,
    location: string,
    title: string,
    description: string,
    status: string,
    listSelected: any[],
    radiumInKm?: number,
    coordinate?: any
  ) => {
    const merchantIds = listSelected.map((item: any) => {
      return item.merchantId;
    });
    const sectionData: Section = {
      contentType,
      viewType,
      sort,
      location,
      title,
      description,
      status
    };
    const variables = !edit
      ? {
          section: sectionData
        }
      : {
          sectionId: section.id,
          section: {
            ...sectionData,
            sort: section.sort // 覆盖 sort，如果需要的话
          }
        };

    if (radiumInKm) {
      // convert to number and check is NaN
      if (isNaN(Number(radiumInKm))) {
        showSnackbar(t('Radius must be a number'), 'error');
        return;
      }
      const radiumInKmNumber = Number(radiumInKm);
      if (radiumInKmNumber <= 0) {
        showSnackbar(t('Radius must be positive'), 'error');
        return;
      }
      variables.section.radiumInKm = radiumInKmNumber;
    }
    console.log('coordinate', coordinate);
    if (coordinate?.latitude === '' && coordinate?.longitude === '') {
      // remove coordinate key on section if both latitude and longitude are empty on
      // coordinate object
      delete variables.section.coordinate;
    } else if (coordinate?.latitude === '' || coordinate?.longitude === '') {
      showSnackbar(t('Latitude and Longitude must be a number'), 'error');
      return;
    } else {
      // convert to number
      coordinate.latitude = Number(coordinate.latitude);
      coordinate.longitude = Number(coordinate.longitude);
      variables.section.coordinate = coordinate;
    }
    if (merchantIds.length > 0) {
      variables.section.merchantIds = merchantIds;
    }

    if (!edit) {
      createAppSection({ variables });
    } else {
      updateAppSection({ variables });
    }
  };

  const NewMenuGroupSchema = Yup.object().shape({
    contentType: Yup.string().required('Content Type is required'),
    viewType: Yup.string().required('View Type is required'),
    location: Yup.string().required('Location is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string(),
    status: Yup.string().required('Status is required'),
    coordinate: Yup.object({
      latitude: Yup.number()
        .min(-90, 'Latitude must be between -90 and 90')
        .max(90, 'Latitude must be between -90 and 90'),
      longitude: Yup.number()
        .min(-180, 'Longitude must be between -180 and 180')
        .max(180, 'Longitude must be between -180 and 180')
    })
  });

  const formik = useFormik({
    initialValues: {
      contentType: section?.contentType ? section?.contentType : '',
      viewType: section?.viewType ? section?.viewType : '',
      location: section?.location ? section?.location : '',
      title: section?.title ? section?.title : '',
      description: section?.description ? section?.description : '',
      status: section?.status ? section?.status : '',
      radiumInKm: section?.radiumInKm ? section?.radiumInKm : null,
      coordinate: getCoordinate(section)
    },
    validationSchema: NewMenuGroupSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (isSubmitting || isLoading) return;
      if (!allowCreated()) {
        showSnackbar(t('Please select at least one merchant'), 'error');
        return;
      }
      try {
        setLoading(true);
        setIsSubmitting(true);
        createSection(
          values.contentType,
          values.viewType,
          values.location,
          values.title,
          values.description,
          values.status,
          listSelected,
          values.radiumInKm,
          values.coordinate
        );
      } catch (error) {
        showSnackbar(error, 'error');
      }
    }
  });

  const allowCreated = () => {
    // case 1: radiumInKm is null and listSelected is empty
    if (!formik.values.radiumInKm && !listSelected?.length) {
      return false;
    }
    // one is empty, one is not empty
    if (
      (formik.values.coordinate.latitude === '' && formik.values.coordinate.longitude !== '') ||
      (formik.values.coordinate.latitude !== '' && formik.values.coordinate.longitude === '')
    ) {
      return false;
    }

    // check latitude and longitude are number NanCheck
    if (
      (formik.values.coordinate.latitude !== '' &&
        isNaN(Number(formik.values.coordinate.latitude))) ||
      (formik.values.coordinate.longitude !== '' &&
        isNaN(Number(formik.values.coordinate.longitude)))
    ) {
      return false;
    }
    return true;
  };

  const { handleSubmit, getFieldProps, touched, errors, resetForm } = formik;

  const handleChangeTab = (event: any, newValue: number) => {
    setValueTab(newValue);
  };

  return (
    <>
      {edit ? (
        <Button sx={{ padding: 0, minWidth: '40px', height: '30px' }} onClick={handleClickOpen}>
          <EditIcon color="secondary" fontSize="small" />
        </Button>
      ) : (
        <Button sx={{ marginTop: '16px' }} variant="contained" onClick={handleClickOpen}>
          {t('Add App Section')}
        </Button>
      )}

      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle sx={{ marginBottom: '20px' }}>
          {section ? t('Update List') : t('Create List')}
        </DialogTitle>
        <Tabs
          value={valueTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
          sx={{ width: '50%', marginLeft: '24px' }}
        >
          <Tab
            label={t('Information')}
            icon={Object.keys(errors)?.length ? <ErrorOutlineIcon color="error" /> : ''}
            iconPosition="end"
            {...a11yProps(0)}
          />
          <Tab
            label={t('Merchants')}
            icon={error && !allowCreated() ? <ErrorOutlineIcon color="error" /> : ''}
            iconPosition="end"
            {...a11yProps(1)}
          />
        </Tabs>

        {valueTab === 1 ? (
          <Box sx={{ height: '90vh' }}>
            <Box
              component="form"
              sx={{
                '& > :not(style)': { m: 1, width: '40%', margin: '20px 24px' }
              }}
              noValidate
              autoComplete="off"
            >
              <SearchStyle
                placeholder={t('Search items...')}
                value={searchValue}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    <Box
                      component={Icon}
                      icon={clear}
                      sx={{ color: 'text.disabled', cursor: 'pointer' }}
                      onClick={() => setSearchValue('')}
                    />
                  </InputAdornment>
                }
              />
            </Box>
            <Typography sx={{ marginLeft: '24px' }}>
              {t('Maximum 4 merchants for the list')}
            </Typography>
            <DialogContent>
              <Card>
                <Scrollbar>
                  <Paper sx={{ width: '100%', overflow: 'hidden', minHeight: 480 }}>
                    <TableContainer style={{ maxHeight: 480 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                          {isLoading ? (
                            <TableRow style={{ height: 53 }}>
                              <TableCell colSpan={8}>
                                <LoadingComponent />
                              </TableCell>
                            </TableRow>
                          ) : (
                            filteredList?.map((row: any) => {
                              const { name, type, merchantId } = row;
                              const isItemSelected = listSelected?.find((item: any) => {
                                return item?.merchantId === merchantId;
                              });
                              return (
                                <TableRow hover key={merchantId} tabIndex={-1} role="checkbox">
                                  <TableCell padding="checkbox">
                                    <Checkbox
                                      checked={Boolean(isItemSelected)}
                                      onClick={() => handleClick(row)}
                                    />
                                  </TableCell>
                                  <TableCell align="left">{name}</TableCell>
                                  <TableCell align="left">{type}</TableCell>
                                </TableRow>
                              );
                            })
                          )}
                        </TableBody>
                        {!isLoading && !filteredList?.length && (
                          <TextNoItem text={`${t('No results found for')} "${searchValue}"`} />
                        )}
                      </Table>
                    </TableContainer>
                  </Paper>
                </Scrollbar>
              </Card>
            </DialogContent>
          </Box>
        ) : (
          <DialogContent sx={{ height: '90vh' }}>
            <FormikProvider value={formik}>
              <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3} sx={{ marginTop: '16px' }}>
                  <Grid item xs={12}>
                    <Stack spacing={3} width="100%">
                      <TextField
                        fullWidth
                        label={t('Title')}
                        {...getFieldProps('title')}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title && t(`${errors.title}`)}
                      />
                      <TextField
                        fullWidth
                        label={t('Description')}
                        {...getFieldProps('description')}
                      />
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <FormControl
                          fullWidth
                          error={Boolean(touched.contentType && errors.contentType)}
                        >
                          <InputLabel>{t('Content Type')}</InputLabel>
                          <Select {...getFieldProps('contentType')} label={t('Content Type')}>
                            {Object.values(AppContentType)?.map((option: any) => (
                              <MenuItem value={option} key={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.contentType && errors.contentType && (
                            <FormHelperText sx={{ color: 'red' }}>
                              {t(`${errors.contentType}`)}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <FormControl fullWidth error={Boolean(touched.location && errors.location)}>
                          <InputLabel>{t('Location')}</InputLabel>
                          <Select {...getFieldProps('location')} label={t('Location')}>
                            {Object.values(AppContentLocation)?.map((option: any) => (
                              <MenuItem value={option} key={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.location && errors.location && (
                            <FormHelperText sx={{ color: 'red' }}>
                              {t(`${errors.location}`)}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <FormControl fullWidth error={Boolean(touched.viewType && errors.viewType)}>
                          <InputLabel>{t('View Type')}</InputLabel>
                          <Select {...getFieldProps('viewType')} label={t('View Type')}>
                            {Object.values(AppViewType)?.map((option: any) => (
                              <MenuItem value={option} key={option}>
                                {convertCamelCase(option)}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.viewType && errors.viewType && (
                            <FormHelperText sx={{ color: 'red' }}>
                              {t(`${errors.viewType}`)}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl fullWidth error={Boolean(touched.status && errors.status)}>
                          <InputLabel>{t('Status')}</InputLabel>
                          <Select {...getFieldProps('status')} label={t('Status')}>
                            {Object.values(ContentStatus)?.map((option: any) => (
                              <MenuItem value={option} key={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.status && errors.status && (
                            <FormHelperText sx={{ color: 'red' }}>
                              {t(`${errors.status}`)}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Stack>

                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField fullWidth label={t('Radius')} {...getFieldProps('radiumInKm')} />
                      </Stack>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                        <TextField
                          fullWidth
                          label={t('Latitude')}
                          defaultValue=""
                          {...(getFieldProps('coordinate.latitude') || '')}
                          error={touched.coordinate?.latitude && !!errors.coordinate?.latitude}
                          helperText={touched.coordinate?.latitude && errors.coordinate?.latitude}
                        />
                        <TextField
                          fullWidth
                          label={t('Longitude')}
                          {...(getFieldProps('coordinate.longitude') || '')}
                          error={Boolean(
                            touched.coordinate?.longitude && errors.coordinate?.longitude
                          )}
                          helperText={
                            touched.coordinate?.longitude &&
                            errors.coordinate?.longitude && (
                              <FormHelperText sx={{ color: 'red' }}>
                                {t(`${errors.coordinate?.longitude}`)}
                              </FormHelperText>
                            )
                          }
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() => {
              handleSubmit();
              setError(true);
            }}
            loading={isSubmitting}
          >
            {section ? t('Update') : t('Create')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
