import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoDiscountedItemQtyProps {
  formik: FormikProps<Promotion>;
  label?: string;
  sx?: CSSProperties;
}
export const PromoDiscountedItemQty: React.FC<promoDiscountedItemQtyProps> = (props) => {
  const { formik, label } = props;
  const { discountedItemQty } = formik.values;
  const { t } = useTranslation();
  const handleDiscountedItemQtyOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('discountedItemQty', value);
  };

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <Label>{label || t('Get how many?')}</Label>
        <TextField
          disabled={discountedItemQty === 'Infinite'}
          placeholder={t('Get Qty')}
          type="text"
          name="discountedItemQty"
          value={discountedItemQty}
          onChange={handleDiscountedItemQtyOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.discountedItemQty && formik.errors.discountedItemQty)}
          helperText={
            formik.touched.discountedItemQty &&
            formik.errors.discountedItemQty &&
            t(`${formik.errors.discountedItemQty}`)
          }
        />
      </FormControl>
    </>
  );
};
