import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useCallback } from 'react';

interface promoPrizeDescriptionProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoPrizeDescription: React.FC<promoPrizeDescriptionProps> = (props) => {
  const { formik, sx = {} } = props;
  const { values, setFieldValue } = formik;
  const { prizeDescription } = values;
  const { t } = useTranslation();
  // prizeDescription

  const handlePrizeDescriptionOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('prizeDescription', e.target.value);
    },
    [setFieldValue]
  );
  return (
    <>
      <FormControl sx={{ width: '100%', ...sx }}>
        <Label>{t('Prize Description')}</Label>
        <TextField
          placeholder={t('Please enter the description of the prize')}
          type="text"
          multiline={true}
          name={'prizeDescription'}
          value={prizeDescription}
          onChange={handlePrizeDescriptionOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.prizeDescription && formik.errors.prizeDescription)}
          helperText={
            formik.touched.prizeDescription &&
            formik.errors.prizeDescription &&
            t(`${formik.errors.prizeDescription}`)
          }
        />
      </FormControl>
    </>
  );
};
