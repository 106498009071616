import { FormikProps, getIn } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoPointsPerDollarProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}

export const PromoPointsPerDollar: React.FC<promoPointsPerDollarProps> = (props) => {
  const { formik, sx = {} } = props;
  const { values, touched, errors } = formik;
  const { loyaltySettings } = values;

  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handlePointsPerDollarOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // number
    const numValue = Number(value);
    formik.setFieldValue('loyaltySettings.pointsPerDollar', numValue);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Earn 2 points for every $1 spent.')}
          type="number"
          name="loyaltySettings.pointsPerDollar"
          value={loyaltySettings?.pointsPerDollar || ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'Points'}</InputAdornment>
          }}
          onChange={handlePointsPerDollarOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            getIn(touched, 'loyaltySettings.pointsPerDollar') &&
              getIn(errors, 'loyaltySettings.pointsPerDollar')
          )}
          helperText={
            getIn(touched, 'loyaltySettings.pointsPerDollar') &&
            getIn(errors, 'loyaltySettings.pointsPerDollar')
          }
        />
      </FormControl>
    </>
  );
};
