import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoDiscountAmountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoDiscountAmount: React.FC<promoDiscountAmountProps> = (props) => {
  const { formik, sx = {} } = props;
  const { isPercentage } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleDiscountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    const isPercentage = formik.values.isPercentage;
    if (isPercentage === true) {
      formik.setFieldValue('percentage', value);
      formik.setFieldValue('discountAmount', 0);
    }
    if (isPercentage === false) {
      formik.setFieldValue('discountAmount', value);
      formik.setFieldValue('percentage', 0);
    }
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Discount Amount')}</Label>
        <TextField
          placeholder={t('Discount Amount')}
          type="text"
          name={isPercentage ? 'percentage' : 'discountAmount'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{isPercentage ? '%' : 'AUD'}</InputAdornment>
            )
          }}
          value={isPercentage ? formik.values.percentage : formik.values.discountAmount}
          onChange={handleDiscountOnChange}
          onBlur={formik.handleBlur}
          error={
            isPercentage ? Boolean(formik.errors.percentage) : Boolean(formik.errors.discountAmount)
          }
          helperText={
            isPercentage
              ? formik.touched.percentage && formik.errors.percentage
              : formik.touched.discountAmount && formik.errors.discountAmount
          }
        />
      </FormControl>
    </>
  );
};
