import { MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { MenuData } from '@type/menu';
import { useLazyQuery } from '@apollo/client';
import { Box } from '@mui/system';
import { Icon } from '@iconify/react';
import { FormikProps } from 'formik';
import { MenuStatus } from '__generated__/globalTypes';
import { GET_ALL_MENU } from '_apis_/queries/menu';
import { RootState, useSelector } from 'redux/store';
import { GET_POS_MENUS } from '_apis_/queries/merchant';
import useGetMenu from './queryMenu';
import { Label } from '../../styles';
import { Promotion } from '@type/promotion';

export interface PromotionTagsSelectProps {
  fieldString: string;
  formik: FormikProps<Promotion>;
  title?: string;
}

export const PromotionTagsSelector: React.FC<PromotionTagsSelectProps> = (
  props: PromotionTagsSelectProps
) => {
  const { t } = useTranslation();
  const { formik, fieldString, title } = props;
  const [selectedMenuId, setSelectedMenuId] = useState<string | null>(null);
  const [promotionTags, setPromotionTags] = useState<(string | null)[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [POSMenusLoading, setPOSMenusLoaidng] = useState<boolean>(true);
  // const authMerchant = useSelector((state: RootState) => state.auth.merchant) || null;
  const authUser = useSelector((state: RootState) => state.auth.user) || null;
  // make sure merchantId is equal to POSMerhcantId!
  const { data: menu, loading: menuLoading } = useGetMenu(
    selectedMenuId ? selectedMenuId : '',
    authUser?.merchantId
  );
  // get all menu id
  const [posMenus] = useLazyQuery(GET_POS_MENUS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setMenusData(response.posMenus);
      setPOSMenusLoaidng(false);
    }
  });

  const [appMenu] = useLazyQuery(GET_ALL_MENU, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setMenusData(response.getMenus);
      setPOSMenusLoaidng(false);
    }
  });

  useEffect(() => {
    if (authUser?.merchantId) {
      posMenus();
    } else {
      appMenu();
    }
  }, [authUser, appMenu, posMenus]);

  useEffect(() => {
    if (menu) {
      setPromotionTags(menu.promotionTags || []);
    }
  }, [menu]);

  const setMenusData = (menus: MenuData[]) => {
    const menu = getPublishedMenu(menus);
    if (menu) {
      setSelectedMenuId(menu.id);
    }
  };

  const getPublishedMenu = (menus: MenuData[]) => {
    if (!menus) return null;
    const publishedMenus = menus.filter((menu) => menu.status === MenuStatus.published);
    if (publishedMenus.length > 0) {
      return publishedMenus[0];
    }
    return null;
  };

  const handleTagsChange = (event: SelectChangeEvent<string[]>) => {
    const selectedTag = event.target.value;
    if (Array.isArray(selectedTag)) {
      setSelectedTags(selectedTag);
      setSelectedItemsToFormik(selectedTag);
    }
  };

  const setSelectedItemsToFormik = (tags: string[]) => {
    formik.setFieldValue(fieldString, tags);
  };

  useEffect(() => {
    const fieldValues = formik.getFieldProps(fieldString).value;
    if (fieldValues) {
      setSelectedTags(fieldValues);
    }
  }, [formik, fieldString]);

  return (
    <>
      <Stack sx={{ marginBottom: '4px' }} direction="column">
        <Label>{t(title || '')}</Label>
        <Stack direction="row" spacing={2}>
          <Select
            multiple
            sx={{ width: '100%' }}
            value={selectedTags}
            onChange={handleTagsChange}
            placeholder="Select Items"
          >
            <MenuItem value="placeholder" disabled>
              Select Items
            </MenuItem>
            {menuLoading || POSMenusLoading ? (
              <MenuItem value="placeholder" disabled>
                Loading...
              </MenuItem>
            ) : (
              promotionTags.map(
                (tag, idx) =>
                  tag && (
                    <MenuItem
                      value={tag}
                      key={idx}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box component="span" sx={{ flexGrow: 1 }}>
                        {tag}
                      </Box>
                      {selectedTags.some((selectedTag) => selectedTag === tag) && (
                        <Icon icon="material-symbols:close" />
                      )}
                    </MenuItem>
                  )
              )
            )}
          </Select>
        </Stack>
      </Stack>
    </>
  );
};
