import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { TableCell, TableRow } from '@mui/material';
import { Promotion } from '@type/promotion';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import {
  convertPromoInput,
  getQueryByPromoType,
  hasOfferVoucherSettings,
  updateEffectiveDateTime
} from 'components/_dashboard/promotion/tools';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fDateTimeSecond } from 'utils/formatTime';

interface PromotionHistoryTableRowProps {
  promotion: Promotion;
}

export const PromotionHistoryTableRow: React.FC<PromotionHistoryTableRowProps> = (props) => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const { promotion } = props;
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const { name, type, description, createdAt } = promotion;
  const { create } = getQueryByPromoType(type);

  const [createPromotionMutation] = useMutation(create, {
    onCompleted(response) {
      showSnackbar(t('Duplicate Success'), 'success');
    },
    refetchQueries: [{ query: GET_ALL_PROMOTIONS }],
    onError(error) {
      showSnackbar(error.message, 'error');
    }
  });

  const onDuplicatePromotion = async (promotion: Promotion) => {
    if (isDuplicateLoading) return;
    setIsDuplicateLoading(true);
    try {
      const effectiveDateTime = updateEffectiveDateTime(promotion.effectiveDateTime);
      const newPromo = convertPromoInput(promotion, false);
      if (newPromo) {
        newPromo.effectiveDateTime = effectiveDateTime;
        // if newPromo contains offerVoucherSettings, update effectiveDateTime
        if (hasOfferVoucherSettings(newPromo)) {
          newPromo.offerVoucherSettings.effectiveDateTime = effectiveDateTime;
        }
      }
      const variables = {
        promotion: {
          ...newPromo
        }
      };
      await createPromotionMutation({ variables });
    } catch (error) {
      if (error instanceof Error) {
        console.log('Error message:', error.message);
        console.log('Stack trace:', error.stack);
      } else {
        console.log('Unknown error:', error);
      }
    } finally {
      setIsDuplicateLoading(false);
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{description}</TableCell>
      <TableCell align="left">{createdAt && fDateTimeSecond(createdAt / 1000)}</TableCell>
      <TableCell align="left">{type}</TableCell>
      <TableCell align="left">
        <LoadingButton
          size="small"
          variant="outlined"
          loading={isDuplicateLoading}
          onClick={() => onDuplicatePromotion(promotion)}
        >
          {t('Duplicate')}
        </LoadingButton>
      </TableCell>
    </TableRow>
  );
};
