import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useEffect } from 'react';

interface PromoFreeNProps {
  formik: FormikProps<Promotion>;
  label?: string;
  sx?: CSSProperties;
}
export const PromoFreeN: React.FC<PromoFreeNProps> = (props) => {
  const { formik, label } = props;
  const { freeN } = formik.values;
  const { t } = useTranslation();
  const handleFreeNOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    formik.setFieldValue('freeN', value);
  };

  useEffect(() => {
    if (freeN === 0 || freeN === '0') {
      formik.setFieldValue('freeN', 'Infinite');
    }
  }, [freeN, formik]);

  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <Label>{label || t('Get how many?')}</Label>
        <TextField
          disabled={freeN === 'Infinite'}
          placeholder={t('Get Qty')}
          type="text"
          name="freeN"
          value={freeN}
          onChange={handleFreeNOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.freeN && formik.errors.freeN)}
          helperText={formik.touched.freeN && formik.errors.freeN && t(`${formik.errors.freeN}`)}
        />
      </FormControl>
    </>
  );
};
