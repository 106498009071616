import { FormikProps } from 'formik';
import React from 'react';
import { Promotion } from '@type/promotion';
import { FormControl } from '@mui/material';
import { hexToRGBA } from 'utils/rgb';
import { PromoSummary } from 'components/_dashboard/promotion/formik/promoSummary';

export interface offerSummaryProps {
  formik: FormikProps<Promotion>;
}

export const OfferSummary: React.FC<offerSummaryProps> = (props) => {
  const { formik } = props;
  const { values } = formik;
  return (
    <FormControl sx={{ width: '100%', margin: '16px' }}>
      <PromoSummary
        promotion={values}
        voucherBackgroundColor={hexToRGBA('#F1F8F2', 1)}
        offerBackgroundColor={hexToRGBA('#FFEEB8', 0.2)}
      />
    </FormControl>
  );
};
