import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, Grid } from '@mui/material';
import { PromoFreeN } from './promoFreeN';

interface promoOfferFreeNProps {
  formik: FormikProps<Promotion>;
}

export const PromoOfferFreeN: React.FC<promoOfferFreeNProps> = (props) => {
  const { formik } = props;

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%' }}>
          <PromoFreeN formik={formik} label="Get how many? (next visit)" />
        </FormControl>
      </Grid>
    </>
  );
};
