import { FormControl, Grid } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Promotion } from '@type/promotion';
import { PromoName } from 'components/_dashboard/promotion/formik/promoName';
import { PromoDescription } from 'components/_dashboard/promotion/formik/promoDescription';
import { PromoNumberOfDeals } from 'components/_dashboard/promotion/formik/promoNumberOfDeals';
import { PromoCustomerSegment } from 'components/_dashboard/promotion/formik/promoCustomerSegment';
import { canMoveProps } from '../../stepper/stepper';
import { baseInfoCheck } from 'components/_dashboard/promotion/tools';

export interface OfferBaseInfoProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
  setFieldTouchedNameFunc: (name: string) => void;
}

export const OfferBaseInfo: React.FC<OfferBaseInfoProps> = (props) => {
  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      setFieldTouchedNameFunc('name');
      setFieldTouchedNameFunc('numberOfDeals');
      baseInfoCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values, setFieldTouchedNameFunc]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);
  return (
    <FormControl sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <PromoName formik={formik} />
        </Grid>
        <Grid item xs={12} md={12}>
          <PromoDescription formik={formik} />
        </Grid>
        <Grid item xs={6} md={6}>
          <PromoNumberOfDeals formik={formik} />
        </Grid>
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6}>
          <PromoCustomerSegment formik={formik} />
        </Grid>
      </Grid>
    </FormControl>
  );
};
