import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
interface promoEffectiveHoursAfterOfferActivatedProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoEffectiveHoursAfterOfferActivated: React.FC<
  promoEffectiveHoursAfterOfferActivatedProps
> = (props) => {
  const { formik, sx = {} } = props;
  const offerVoucherSettings = formik.values.offerVoucherSettings;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleEffectiveHoursAfterOfferActivatedOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // make sure the value is a number
    if (isNaN(Number(e.target.value))) {
      return;
    }
    const numberValue = Number(e.target.value);
    formik.setFieldValue('offerVoucherSettings.effectiveHoursAfterOfferActivated', numberValue);
  };
  return (
    <FormControl sx={defaultFormControlStyles}>
      <Label>
        {t('Next Visit Voucher Activation Time')}
        <Tooltip
          title={t('Number of hours to wait after the offer is activated before it can be used.')}
        >
          <IconButton>
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Label>
      <TextField
        sx={{ marginBottom: '24px' }}
        placeholder={t('Effective Hours')}
        type="number"
        name="effectiveHoursAfterOfferActivated"
        value={offerVoucherSettings?.effectiveHoursAfterOfferActivated}
        onChange={handleEffectiveHoursAfterOfferActivatedOnChange}
      />
    </FormControl>
  );
};
