import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoMinimumAmountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoMinimumAmount: React.FC<promoMinimumAmountProps> = (props) => {
  const { formik, sx = {} } = props;
  const { minimumAmount } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleMinimumAmountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('minimumAmount', value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Minimum Amount?')}
          type="text"
          name="minimumAmount"
          value={minimumAmount}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'AUD'}</InputAdornment>
          }}
          onChange={handleMinimumAmountOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.minimumAmount && formik.errors.minimumAmount)}
          helperText={
            formik.touched.minimumAmount &&
            formik.errors.minimumAmount &&
            t(`${formik.errors.minimumAmount}`)
          }
        />
      </FormControl>
    </>
  );
};
