import { FormControl, Grid } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Promotion } from '@type/promotion';
import { PromoName } from 'components/_dashboard/promotion/formik/promoName';
import { PromoDescription } from 'components/_dashboard/promotion/formik/promoDescription';
import { canMoveProps } from '../../stepper/stepper';
import { offerVoucherBaseInfoCheck } from 'components/_dashboard/promotion/tools';

export interface offerVoucherBaseInfoProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
}

export const OfferVoucherBaseInfo: React.FC<offerVoucherBaseInfoProps> = (props) => {
  const { formik, setCanMoveFunc } = props;

  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      offerVoucherBaseInfoCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <PromoName formik={formik} isOffer={true} />
        </Grid>
        <Grid item xs={12} md={12}>
          <PromoDescription formik={formik} isOffer={true} />
        </Grid>
      </Grid>
    </FormControl>
  );
};
