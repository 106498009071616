import { FormControl, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@type/promotion';
import { PromotionType } from '__generated__/globalTypes';
import {
  DiscountType,
  useDiscountTypeSelector
} from 'components/_dashboard/promotion/formik/useDiscountTypeSelector';
import { PromoMinimumAmount } from 'components/_dashboard/promotion/formik/PromoMinimumAmount';
import { PromotionTagsSelector } from '../tagsSelect';
import { useExcludedPromotionTags } from 'components/_dashboard/promotion/formik/useExcludedPromotionTags';
import { Label } from 'components/_dashboard/promotion/styles';
import { PromoBuyN } from 'components/_dashboard/promotion/formik/promoBuyN';
import ItemButton, { ItemButtonColor } from '../../../formik/itemButton';
import { canMoveProps } from '../../stepper/stepper';
import { offerDetailsCheck } from 'components/_dashboard/promotion/tools';

export interface OfferDetailsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
  setFieldTouchedNameFunc: (name: string) => void;
}

export const OfferDetails: React.FC<OfferDetailsProps> = (props) => {
  const { t } = useTranslation();

  const { formik, setCanMoveFunc, setFieldTouchedNameFunc } = props;
  const promotionName = formik.values.name;
  const includedPromotionTags = formik.values.includedPromotionTags;
  const type = formik.values.type;
  const {
    excludedPromoTagsCheckBox,
    excludedPromoTagsInput,
    excludedPromoTags,
    isExcludeTagsChecked
  } = useExcludedPromotionTags({ formik, isOffer: true });

  const handleIncludedPromotionTagsOnClick = (tag: string) => {
    // get an copy
    const tags = includedPromotionTags;
    if (tags && tags.includes(tag)) {
      // remove tag
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      formik.setFieldValue('includedPromotionTags', tags);
    }
  };
  const { discountTypeElement, discountType } = useDiscountTypeSelector({ formik });

  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      setFieldTouchedNameFunc('buyN');
      setFieldTouchedNameFunc('minimumAmount');
      offerDetailsCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values, setFieldTouchedNameFunc]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Stack spacing={1}>
        <Typography fontWeight={'bold'} variant="h3" gutterBottom>
          {promotionName}
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            {discountTypeElement}
          </Grid>
          {type === PromotionType.spend && (
            <>
              <Grid item xs={12} md={6}>
                <Label>{t('Spend Amount')}</Label>
                <PromoMinimumAmount formik={formik} />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedPromotionTags" formik={formik} />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
                  {includedPromotionTags &&
                    includedPromotionTags.map((tag, index) => (
                      <div key={index}>
                        <ItemButton
                          onClick={() => handleIncludedPromotionTagsOnClick(tag)}
                          content={tag}
                        />
                      </div>
                    ))}
                </Stack>
              </Grid>
            </>
          )}
          {type === PromotionType.buynGetFree && (
            <>
              <Grid item xs={12} md={6}>
                {discountType === DiscountType.spend && (
                  <>
                    <Label>{t('Spend Amount')}</Label>
                    <PromoMinimumAmount formik={formik} />
                  </>
                )}
                {discountType === DiscountType.buyN && <PromoBuyN formik={formik} />}
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl sx={{ width: '100%' }}>
                  <Label>{t('Of what?')}</Label>
                  <PromotionTagsSelector fieldString="includedPromotionTags" formik={formik} />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12}>
            <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
              {includedPromotionTags &&
                includedPromotionTags.map((tag, index) => (
                  <div key={index}>
                    <ItemButton
                      color={ItemButtonColor.OFFER}
                      onClick={() => handleIncludedPromotionTagsOnClick(tag)}
                      content={tag}
                    />
                  </div>
                ))}
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            {excludedPromoTagsCheckBox}
          </Grid>

          {isExcludeTagsChecked && (
            <>
              <Grid item xs={12} md={6}>
                {excludedPromoTagsInput}
              </Grid>
              <Grid item xs={12} md={12}>
                {excludedPromoTags}
              </Grid>
            </>
          )}
        </Grid>

        {/* {type === PromotionType.spend && <ExcludePromoTagsSelector formik={formik} />}
        {type === PromotionType.spend && <IncludedGetItemsPromoTagSelector formik={formik} />}

        {type === PromotionType.buynGetFree && <BuyNSelector formik={formik} />}
        {type === PromotionType.buynGetFree && <ExcludePromoTagsSelector formik={formik} />}
        {type === PromotionType.buynGetFree && <FreeNSelector formik={formik} />}
        {type === PromotionType.buynGetFree && <BuyNDiscountTypeSelector formik={formik} />} */}
      </Stack>
    </FormControl>
  );
};
