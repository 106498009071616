import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { Card, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { Promotion } from '../../../../@type/promotion';

// import DatePicker from '@mui/lab/DatePicker';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import { NavigateFunction, useLocation, useParams } from 'react-router';
import { createStrNumberValidation, createStringValidation } from 'utils/regexConfig';
import { useTranslation } from 'react-i18next';
import { FLOAT } from '@type/global';
import LoadingComponent from 'components/LoadingComponent';
import {
  convertPromoInput,
  getInitialPromotionState,
  getQueryByPromoType,
  removeTypenameKey
} from '../tools';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { PromotionType } from '__generated__/globalTypes';
import { usePromoStepper } from './stepper/stepper';
import { StepLabel, offerSteps } from './stepper/steps';
import { OfferBaseInfo } from './tabs/offer/baseInfo';
import { OfferDetails } from './tabs/offer/details';
import { OfferConditions } from './tabs/offer/conditions';
import { OfferVoucherDetails } from './tabs/offer/voucherDetails';
import { OfferSummary } from './tabs/offer/summary';
import { OfferVoucherBaseInfo } from './tabs/offer/voucherBaseInfo';
import { OfferVoucherConditions } from './tabs/offer/voucherConditions';

export const yupCustomRequired = (msg: string) => Yup.number().required(msg);

export const onRedirectDiscountCompleted = (navigate: NavigateFunction) => {
  navigate(PATH_DASHBOARD.promotion.list);
};

export default function CreateOfferForm() {
  const { showSnackbar } = useSnackbarHelper();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type') || PromotionType.buynGetFree;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEdit = params.name ? true : false;
  const [promotion] = useState<Promotion>(getInitPromo(type as PromotionType));
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: promotion,
    validationSchema: Yup.object({
      name: createStringValidation({ isRequired: true }),
      // minimumAmount is required,FLOAT, equal or greater than 0
      minimumAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      // numberOfDeals is required, integer,greater than 0
      numberOfDeals: createStrNumberValidation({}),
      // percentage is required, FLOAT,greater than 0
      percentage: createStrNumberValidation({ type: FLOAT }),
      // discountAmount is required, FLOAT,greater than 0
      discountAmount: createStrNumberValidation({ type: FLOAT }),
      upToAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      discountedItemQty: createStrNumberValidation({}),
      buyN: createStrNumberValidation({}),
      freeN: createStrNumberValidation({})
    }),
    onSubmit: async () => {}
  });
  const { query, create, update, responseKey } = getQueryByPromoType(formik.values.type);
  const { setFieldValue, setFieldTouched } = formik;
  const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } =
    usePromoStepper({
      steps: offerSteps,
      isSubmitting,
      action: CreateOrUpdatePromotion(isEdit),
      promotion: formik.values
    });
  const setFieldTouchedName = useCallback(
    (name: string) => {
      setFieldTouched(name, true);
    },
    [setFieldTouched]
  );
  // query data if this is edit page
  useQuery(query, {
    variables: { promotionId: params.name },
    fetchPolicy: 'network-only',
    skip: !params.name,
    onCompleted(response) {
      setIsLoading(false);
      const promotionDataResp = response?.[responseKey];
      if (!promotionDataResp) return;
      removeTypenameKey(promotionDataResp);
      const promotionData: Partial<Promotion> = {};
      (Object.keys(promotionDataResp) as Array<keyof typeof promotionDataResp>).forEach((key) => {
        if (promotionDataResp[key] === null) return;
        promotionData[key as keyof Promotion] = promotionDataResp[key];
      });
      Object.keys(promotionData).forEach((key) => {
        const promotionKey = key as keyof Promotion;
        setFieldValue(promotionKey, promotionData[promotionKey] ?? formik.values[promotionKey]);
      });
    },
    onError(error) {
      setIsLoading(false);
    }
  });

  const [createPromotionMutation] = useMutation(create, {
    onCompleted(response) {
      onRedirectDiscountCompleted(navigate);
      showSnackbar(t('Create Success'), 'success');
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: GET_ALL_PROMOTIONS }],
    onError(error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  });

  const [updatePromotionMutation] = useMutation(update, {
    onCompleted(response) {
      onRedirectDiscountCompleted(navigate);
      showSnackbar(t('Update Success'), 'success');
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: GET_ALL_PROMOTIONS }],
    onError(error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  });

  function createPromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const variables = {
        promotion: {
          ...convertPromoInput(formik.values, isPublished)
        }
      };
      createPromotionMutation({ variables });
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }

  function updatePromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const variables = {
        promotionId: params.name,
        promotion: {
          ...convertPromoInput(formik.values, isPublished)
        }
      };
      updatePromotionMutation({ variables });
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }
  function CreateOrUpdatePromotion(isEdit: boolean) {
    if (isEdit) {
      return updatePromotion;
    } else {
      return createPromotion;
    }
  }
  function getInitPromo(type: PromotionType) {
    switch (type) {
      case PromotionType.spend:
        return getInitialPromotionState(PromotionType.spend, true);
      case PromotionType.percentage:
        return getInitialPromotionState(PromotionType.percentage, true);
      case PromotionType.buynGetFree:
        return getInitialPromotionState(PromotionType.buynGetFree, true);
      case PromotionType.luckyDraw:
        return getInitialPromotionState(PromotionType.luckyDraw, true);
      case PromotionType.loyalty:
        return getInitialPromotionState(PromotionType.loyalty, true);
      default:
        // Handle the default case more appropriately or log an error if the type is unknown
        console.error('Unhandled promotion type:', type);
        return getInitialPromotionState(PromotionType.buynGetFree, true);
    }
  }

  if (isLoading && isEdit) {
    return <LoadingComponent />;
  }

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <Card sx={{ p: 3 }}>
              <BreadcrumbsComponent />
              {currentStep === StepLabel.OfferBaseInfo && (
                <OfferBaseInfo
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.OfferDetails && (
                <OfferDetails
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.OfferConditions && (
                <OfferConditions formik={formik} setCanMoveFunc={setCanMoveFunc} />
              )}
              {currentStep === StepLabel.VoucherBaseInfo && (
                <OfferVoucherBaseInfo formik={formik} setCanMoveFunc={setCanMoveFunc} />
              )}
              {currentStep === StepLabel.VoucherDetails && (
                <OfferVoucherDetails formik={formik} setCanMoveFunc={setCanMoveFunc} />
              )}
              {currentStep === StepLabel.VoucherConditions && (
                <OfferVoucherConditions formik={formik} setCanMoveFunc={setCanMoveFunc} />
              )}
              {currentStep === StepLabel.OfferSummary && <OfferSummary formik={formik} />}
              <MoveToStepComponent />
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
