import { FormControl, Grid, Stack, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@type/promotion';

import { EffectiveDateTimeType } from '../../../effectiveDateTime/type/type';
import { useMutipleEffectiveDateTime } from '../../../effectiveDateTime';
import { DeliveredTypeSelector } from 'components/_dashboard/promotion/formik/promoDeliveredTypeSelector';
import { PromoEffectiveHoursAfterOfferActivated } from 'components/_dashboard/promotion/formik/promoEffectiveHoursAfterOfferActivated';
import { PromotionType } from '__generated__/globalTypes';
import { Label } from 'components/_dashboard/promotion/styles';
import { usePromoMinimumAmount } from 'components/_dashboard/promotion/formik/usePromoMinimumAmount';
import { canMoveProps } from '../../stepper/stepper';
import { offerConditionCheck } from 'components/_dashboard/promotion/tools';

export interface offerConditionsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
}

export const OfferConditions: React.FC<offerConditionsProps> = (props) => {
  const { t } = useTranslation();
  const { formik, setCanMoveFunc } = props;
  const promotionName = formik.values.name;
  const promoType = formik.values.type;
  const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
    formik.setFieldValue('effectiveDateTime', newList);
  };
  const { effectiveDateTime } = useMutipleEffectiveDateTime({
    dateTimesList: formik.values.effectiveDateTime,
    setDateTimeList: setEffectiveDateTime,
    isOffer: true
  });
  const { promoMinimumAmountCheckBox, promoMinimumAmountInput, isRequireMinimumAmountChecked } =
    usePromoMinimumAmount({ formik });

  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      offerConditionCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Typography fontWeight={'bold'} variant="h3" gutterBottom>
          {promotionName}
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <DeliveredTypeSelector formik={formik} />
          </Grid>
          {promoType === PromotionType.buynGetFree && (
            <>
              <Grid item xs={12} md={12}>
                {promoMinimumAmountCheckBox}
              </Grid>
              {isRequireMinimumAmountChecked && (
                <>
                  <Grid item xs={12} md={6}>
                    <Label>{t('Minimum Spend')}</Label>
                    {promoMinimumAmountInput}
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </>
              )}
            </>
          )}
          <Grid item xs={12} md={6}>
            <PromoEffectiveHoursAfterOfferActivated formik={formik} />
          </Grid>
        </Grid>
        {effectiveDateTime}
      </Stack>
    </FormControl>
  );
};
