import { createSlice } from '@reduxjs/toolkit';
import { MerchantData } from '../../@type/merchant';

// ----------------------------------------------------------------------

type MerchantState = {
  isLoading: boolean;
  error: boolean;
  merchantList: MerchantData[];
};

const initialState: MerchantState = {
  isLoading: false,
  error: false,
  merchantList: []
};

const slice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state: any) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state: any, action: any) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MERCHANT
    getMerchantsSuccess(state: any, action: any) {
      state.isLoading = false;
      state.merchantList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
export const { getMerchantsSuccess } = slice.actions;
// Actions

// ----------------------------------------------------------------------
