import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { FormikProps, getIn } from 'formik';
import { Promotion } from '@type/promotion';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { Icon } from '@iconify/react';
import { dispatch, RootState, useSelector } from 'redux/store';
import { fetchPromotionList } from 'redux/slices/promotion';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { getPromoIds } from '../tools';

interface PromoTypeProp {
  formik: FormikProps<Promotion>;
  promoValidator?: (promo: Promotion) => boolean;
  formikPath?: string;
}

export const PromoSelector = (props: PromoTypeProp) => {
  const { formik, promoValidator, formikPath = 'resultingPromotions' } = props;
  const { setFieldValue, values, touched, errors } = formik;
  const { validPromotions, isLoading } = useSelector((state: RootState) => state.promotion);
  const [selectedPromo, setSelectedPromo] = useState<Promotion[]>([]);
  const { showSnackbar } = useSnackbarHelper();

  // Use useMemo to memoize initialSelectedPromoIds to avoid recomputation on each render
  const initialSelectedPromoIds = useMemo(
    () => getIn(values, formikPath) || [],
    [values, formikPath]
  );

  const getSelectedPromoIds = getPromoIds(selectedPromo);

  // Update selectedPromo state when initial values change or validPromotions are loaded
  useEffect(() => {
    if (initialSelectedPromoIds.length && validPromotions.length) {
      const initialSelectedPromos = validPromotions.filter((promo) =>
        initialSelectedPromoIds.includes(promo.id)
      );
      setSelectedPromo(initialSelectedPromos);
    }
  }, [initialSelectedPromoIds, validPromotions]);

  const handlePromoChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const selectedPromoIds = event.target.value as string[];
      const selectedPromoList = validPromotions.filter((promo) =>
        selectedPromoIds.includes(promo.id)
      );

      const isPromoTypeValid = selectedPromoList.every((promo) => {
        if (!promoValidator) {
          return true;
        }
        return promoValidator(promo);
      });

      if (!isPromoTypeValid) {
        showSnackbar('The selected promotion type is not allowed.', 'error');
        return;
      }

      setSelectedPromo(selectedPromoList);
      const ids = getPromoIds(selectedPromoList);
      setFieldValue(formikPath, ids);
    },
    [validPromotions, formikPath, showSnackbar, setFieldValue, promoValidator]
  );

  useEffect(() => {
    dispatch(fetchPromotionList());
  }, []);

  return (
    <Stack sx={{ marginBottom: '4px' }} direction="column">
      <Stack direction="row" spacing={2}>
        <FormControl sx={{ width: '100%' }}>
          <Select
            name={formikPath}
            multiple
            sx={{ width: '100%' }}
            value={getSelectedPromoIds}
            onChange={handlePromoChange}
            placeholder="Select Items"
            error={Boolean(getIn(touched, formikPath) && getIn(errors, formikPath))}
          >
            <MenuItem value="placeholder" disabled>
              Select Promotions
            </MenuItem>
            {isLoading ? (
              <MenuItem value="placeholder" disabled>
                Loading...
              </MenuItem>
            ) : (
              validPromotions.map(
                (promo) =>
                  (!promoValidator || promoValidator(promo)) &&
                  promo && (
                    <MenuItem
                      value={promo.id}
                      key={promo.id}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <Box component="span" sx={{ flexGrow: 1 }}>
                        {promo.name}
                      </Box>
                      {selectedPromo.some((sPromo) => sPromo.id === promo.id) && (
                        <Icon icon="material-symbols:close" />
                      )}
                    </MenuItem>
                  )
              )
            )}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};
