import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoUpToAmountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoUpToAmount: React.FC<promoUpToAmountProps> = (props) => {
  const { formik, sx = {} } = props;
  const { upToAmount } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleUpToAmountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('upToAmount', value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Up to Amount')}</Label>
        <TextField
          placeholder={t('Amount')}
          type="text"
          name="Up to Amount"
          value={upToAmount}
          onChange={handleUpToAmountOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.upToAmount && formik.errors.upToAmount)}
          helperText={
            formik.errors.upToAmount && formik.errors.upToAmount && t(`${formik.errors.upToAmount}`)
          }
        />
      </FormControl>
    </>
  );
};
