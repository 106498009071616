import { TableBody, TableCell, TableRow, Typography } from '@mui/material';

interface TextNoItemProps {
  text: string;
}

export default function TextNoItem({ text }: TextNoItemProps) {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
          <Typography variant="body2" align="center">
            {text}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
