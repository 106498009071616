import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { Promotion } from '@type/promotion';
import { FormikProps } from 'formik';
import { SyntheticEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PromoMinimumAmount } from './PromoMinimumAmount';

interface IPromoMinimumAmountProps {
  formik: FormikProps<Promotion>;
}

export const usePromoMinimumAmount = (props: IPromoMinimumAmountProps) => {
  const { t } = useTranslation();
  const { formik } = props;
  const { minimumAmount } = formik.values;
  const [isRequireMinimumAmountChecked, setIsRequireMinimumAmountChecked] = useState(
    minimumAmount ? Number(minimumAmount) > 0 : false
  );
  const isOffer = formik.values.isOffer || false;

  const checkBoxStyle = {
    color: isOffer ? '#DCC179' : 'default',
    '&.Mui-checked': {
      color: isOffer ? '#DCC179' : 'primary'
    }
  };
  const handleIsRequireMinimumAmountChecked = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    if (!checked) {
      formik.setFieldValue('minimumAmount', 0);
    }
    setIsRequireMinimumAmountChecked(checked);
  };

  const promoMinimumAmountCheckBox = (
    <FormControl sx={{ width: '100%' }}>
      <FormControlLabel
        value={isRequireMinimumAmountChecked}
        checked={isRequireMinimumAmountChecked}
        onChange={handleIsRequireMinimumAmountChecked}
        control={<Checkbox sx={checkBoxStyle} />}
        label={t('Require a minimum spend')}
        labelPlacement="end"
      />
    </FormControl>
  );

  const promoMinimumAmountInput = isRequireMinimumAmountChecked && (
    <PromoMinimumAmount formik={formik} />
  );

  return { promoMinimumAmountCheckBox, promoMinimumAmountInput, isRequireMinimumAmountChecked };
};
