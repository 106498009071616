import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, Grid } from '@mui/material';
import { PromoDiscountedItemQty } from './promoDiscountedItemQty';

interface spendpromoOfferSpendDiscountItemQty {
  formik: FormikProps<Promotion>;
}

export const PromoOfferSpendDiscountItemQty: React.FC<spendpromoOfferSpendDiscountItemQty> = (
  props
) => {
  const { formik } = props;

  return (
    <>
      <Grid item xs={12} md={6}>
        <FormControl sx={{ width: '100%' }}>
          <PromoDiscountedItemQty formik={formik} label="Get how many? (next visit)" />
        </FormControl>
      </Grid>
    </>
  );
};
