import React from 'react';
import { Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';
import plusIcon from '@iconify/icons-eva/plus-outline';
import closeIcon from '@iconify/icons-eva/close-fill';
import { EffectiveDateTimeType, useEffectDateTimeList, weekdayList } from './type/type';
import { RecurringWeekDays } from './weekDays';
import { EffectDateSelector } from './effectDateSelector';
import moment from 'moment';
import { convertTime } from 'utils/helperTime';
import { darkenColor } from 'utils/rgb';

interface EffectiveDateTImeProps {
  effectiveDateTime: EffectiveDateTimeType;
  index: number;
}

const EffectiveDateTIme: React.FC<EffectiveDateTImeProps> = (props) => {
  const { t } = useTranslation();
  const { dateTimeList, setDateTimeList, isOffer = false } = useEffectDateTimeList();
  const { effectiveDateTime, index } = props;
  const textColor = isOffer ? '#DCC179' : '#00AB55';
  const theme = useTheme();
  const btnBackgroudColor = isOffer ? '#DCC179' : theme.palette.primary.main;
  const handleAddEffDateTime = () => {
    // make an copy
    const copyList = [...dateTimeList];
    const tempDate: EffectiveDateTimeType = {
      startDate: moment().format('yyyy-MM-DD'),
      endDate: moment().format('yyyy-MM-DD'),
      hours: [
        {
          openStart: convertTime(moment().startOf('day'), 'time'),
          openEnd: convertTime(moment().endOf('day'), 'time')
        }
      ],
      recurringWeekDays: weekdayList
    };
    copyList.push(tempDate);
    setDateTimeList(copyList);
  };

  const handleDeleteEffDateTime = (index: number) => {
    const copyList = [...dateTimeList];
    copyList.splice(index, 1);
    setDateTimeList(copyList);
  };

  return (
    <div className="recurring-form">
      <div className="recurring-form__content">
        <Box
          sx={{
            padding: '12px 0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography sx={{ color: textColor, fontWeight: 700 }}>
            {t('Effective date time')}
          </Typography>
          {dateTimeList.length > 1 && (
            <Icon
              icon={closeIcon}
              width={32}
              height={32}
              color="#00AB55"
              cursor="pointer"
              onClick={() => handleDeleteEffDateTime(index)}
            />
          )}
        </Box>
        <Divider light />
        <Stack spacing={{ xs: 3 }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <RecurringWeekDays weekDays={effectiveDateTime.recurringWeekDays} index={index} />
          </Stack>
          <EffectDateSelector index={index} effectDate={effectiveDateTime} />

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button
              sx={{
                width: '100%',
                backgroundColor: btnBackgroudColor,
                '&:hover': {
                  backgroundColor: darkenColor(btnBackgroudColor, 20)
                },
                color: '#FFFFFF'
              }}
              onClick={handleAddEffDateTime}
            >
              <Icon icon={plusIcon} width={16} height={16} />
              &nbsp;
              <span>{t('Add effective date time')}</span>
            </Button>
          </Box>
        </Stack>
      </div>
    </div>
  );
};

export const useEffectiveDateTime = (props: EffectiveDateTImeProps) => {
  const { effectiveDateTime, index } = props;

  const DateTime = <EffectiveDateTIme effectiveDateTime={effectiveDateTime} index={index} />;

  return { DateTime };
};
