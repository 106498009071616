import { Icon } from '@iconify/react';
import { Promotion } from '@type/promotion';
import React, { useEffect, useState } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import clear from '@iconify/icons-ic/clear';
import {
  Box,
  Card,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from '@mui/material';
import { SearchStyle } from '../merchants/MerchantList';
import Scrollbar from 'components/Scrollbar';
import LoadingComponent from 'components/LoadingComponent';
import { PromotionListHead } from 'components/_dashboard/promotion/list';
import SearchNotFound from 'components/SearchNotFound';
import { applySortFilter, getComparator } from 'utils/functions';
import { fDateTimeSecond } from 'utils/formatTime';
import { useTranslation } from 'react-i18next';
import { PromotionHistoryTableRow } from './PromotionHistoryTableRow';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'type', label: 'Coupon Type', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false }
];

interface PromotionHistoryTableProps {
  promotionList: Promotion[];
  isOffer: boolean;
  isLoading: boolean;
}

export const PromotionHistoryTable: React.FC<PromotionHistoryTableProps> = (props) => {
  const { promotionList, isOffer, isLoading } = props;
  const { t } = useTranslation();
  const [filteredPromotion, setFilteredPromotion] = React.useState<Promotion[]>([]);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchValue, setSearchValue] = useState<string>('');

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const sortedPromotionList = applySortFilter(promotionList, getComparator(order, orderBy), '');
    const searchedPromotion = sortedPromotionList.filter((promotion) => {
      return (
        promotion.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
        promotion.description?.toLowerCase().includes(searchValue.toLowerCase()) ||
        fDateTimeSecond(promotion.createdAt / 1000)
          ?.toLowerCase()
          .includes(searchValue.toLowerCase()) ||
        promotion.type?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    setFilteredPromotion(searchedPromotion);
  }, [searchValue, promotionList, order, orderBy]);

  const isPromotionNotFound = filteredPromotion?.length === 0;
  const isNoItem = promotionList?.length === 0;
  return (
    <>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '40ch' }
        }}
        noValidate
        autoComplete="off"
      >
        <SearchStyle
          placeholder={t('Search promotion...')}
          value={searchValue}
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <Box
                component={Icon}
                icon={clear}
                sx={{ color: 'text.disabled', cursor: 'pointer' }}
                onClick={() => setSearchValue('')}
              />
            </InputAdornment>
          }
        />
      </Box>

      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <PromotionListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
                isOffer={isOffer}
              />
              <TableBody>
                {isLoading ? (
                  <TableRow style={{ height: 53 }}>
                    <TableCell colSpan={8}>
                      <LoadingComponent />
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredPromotion
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return <PromotionHistoryTableRow promotion={row} key={index} />;
                    })
                )}
              </TableBody>
              {isPromotionNotFound && !isLoading && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={searchValue} isNoItem={isNoItem} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredPromotion.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};
