import { FormControl, Grid, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Promotion } from '@type/promotion';

import { PromotionType } from '__generated__/globalTypes';
import { PromoOfferSpendDiscountItemQty } from 'components/_dashboard/promotion/formik/promoOfferSpendPromoGetTagsSelector';
import { PromoOfferItemGetTags } from 'components/_dashboard/promotion/formik/promoOfferItemGetTags';
import { PercentageSelector } from 'components/_dashboard/promotion/formik/percentageSelector';
import {
  FieldPath,
  useExcludedPromotionTags
} from 'components/_dashboard/promotion/formik/useExcludedPromotionTags';
import { Label } from 'components/_dashboard/promotion/styles';
import { PromoDiscountAmount } from 'components/_dashboard/promotion/formik/promoDiscountAmount';
import { PromoOfferFreeN } from 'components/_dashboard/promotion/formik/promoOfferFreeN';
import { useQtyRestricted } from 'components/_dashboard/promotion/formik/useQtyRestricted';
import { canMoveProps } from '../../stepper/stepper';
import { offerVoucherDetailsCheck } from 'components/_dashboard/promotion/tools';
import { PromoApplyDiscountToOptions } from 'components/_dashboard/promotion/formik/promoApplyDiscountToOptions';

export interface voucherDetailsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
}

export const OfferVoucherDetails: React.FC<voucherDetailsProps> = (props) => {
  const { t } = useTranslation();
  const { formik, setCanMoveFunc } = props;
  const promotionName = formik.values.offerVoucherSettings?.offerName || '';
  const { type } = formik.values;
  const {
    excludedPromoTagsCheckBox,
    excludedPromoTagsInput,
    excludedPromoTags,
    isExcludeTagsChecked
  } = useExcludedPromotionTags({ formik, path: FieldPath.OFFERSETTING });
  const { qtyRestrictedRadioGroup } = useQtyRestricted({
    formik,
    isOffer: true
  });

  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      offerVoucherDetailsCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <Typography fontWeight={'bold'} variant="h3" gutterBottom>
          {promotionName}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        {qtyRestrictedRadioGroup}
      </Grid>
      {type === PromotionType.spend && (
        <>
          <PromoOfferSpendDiscountItemQty formik={formik} />
          <PromoOfferItemGetTags formik={formik} />
        </>
      )}
      {type === PromotionType.buynGetFree && (
        <>
          <PromoOfferFreeN formik={formik} />
          <PromoOfferItemGetTags formik={formik} />
        </>
      )}
      {/* excluede */}
      <Grid item xs={12} md={12}>
        {excludedPromoTagsCheckBox}
      </Grid>
      {isExcludeTagsChecked && (
        <>
          <Grid item xs={12} md={6}>
            {excludedPromoTagsInput}
          </Grid>
          <Grid item xs={12} md={12}>
            {excludedPromoTags}
          </Grid>
        </>
      )}
      {type === PromotionType.buynGetFree && (
        <>
          <Grid item xs={12} md={12}>
            <FormControl sx={{ width: '100%' }}>
              <Label>{t('Get Item Discount')}</Label>
              <PercentageSelector formik={formik} isOffer={false} />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <PromoDiscountAmount formik={formik} />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={12}>
        <PromoApplyDiscountToOptions formik={formik} />
      </Grid>
    </Grid>
  );
};
