/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AppContentLocation {
  account = "account",
  category = "category",
  home = "home",
  notification = "notification",
  voucher = "voucher",
}

export enum AppContentType {
  Category = "Category",
  Featured = "Featured",
  New = "New",
  Popular = "Popular",
  Recommended = "Recommended",
}

export enum AppService {
  booking = "booking",
  delivery = "delivery",
  dinein = "dinein",
  onlineMenu = "onlineMenu",
  pickup = "pickup",
}

export enum AppViewType {
  banner = "banner",
  bannerCarousel = "bannerCarousel",
  bannerSlider = "bannerSlider",
  carousel = "carousel",
  grid = "grid",
  horizontalList = "horizontalList",
  verticalList = "verticalList",
}

export enum ContentStatus {
  draft = "draft",
  published = "published",
}

export enum GiftCardAccountAdjustmentType {
  deduct = "deduct",
  newGiftCard = "newGiftCard",
  other = "other",
  topUp = "topUp",
  voidGiftCard = "voidGiftCard",
}

export enum GiftCardAdjustmentType {
  credit = "credit",
  debit = "debit",
}

export enum GiftCardStatus {
  active = "active",
  expired = "expired",
  redeemed = "redeemed",
  void = "void",
}

export enum MenuStatus {
  draft = "draft",
  published = "published",
}

export enum MerchantStatus {
  active = "active",
  inactive = "inactive",
  pending = "pending",
}

export enum MerchantType {
  Bakery = "Bakery",
  Bar = "Bar",
  Cafe = "Cafe",
  Club = "Club",
  Grocery = "Grocery",
  KTV = "KTV",
  Other = "Other",
  Restaurant = "Restaurant",
  Retail = "Retail",
  Salon = "Salon",
  Supermarket = "Supermarket",
}

export enum OrderAdjustmentType {
  cardSurcharge = "cardSurcharge",
  discount = "discount",
  holidaySurcharge = "holidaySurcharge",
  otherSurcharge = "otherSurcharge",
  surcharge = "surcharge",
  tax = "tax",
  tip = "tip",
}

export enum OrderFrom {
  orderbuddyAppAndroid = "orderbuddyAppAndroid",
  orderbuddyAppIos = "orderbuddyAppIos",
  orderbuddyweb = "orderbuddyweb",
  thirdParty = "thirdParty",
}

export enum OrderSourceType {
  mobile = "mobile",
  pos = "pos",
  thirdParty = "thirdParty",
  web = "web",
}

export enum OrderType {
  delivery = "delivery",
  dinein = "dinein",
  foodcourt = "foodcourt",
  pickup = "pickup",
  takeaway = "takeaway",
}

export enum PaymentType {
  alipay = "alipay",
  card = "card",
  cash = "cash",
  other = "other",
  payAtCounter = "payAtCounter",
  paylater = "paylater",
  paypal = "paypal",
  wechatPay = "wechatPay",
}

export enum PromotionStatus {
  draft = "draft",
  published = "published",
}

export enum PromotionType {
  buynGetFree = "buynGetFree",
  loyalty = "loyalty",
  luckyDraw = "luckyDraw",
  percentage = "percentage",
  spend = "spend",
  voucher = "voucher",
}

export enum PromotionUseType {
  employee = "employee",
  giftcard = "giftcard",
  luckyDraw = "luckyDraw",
  offer = "offer",
  promotion = "promotion",
  refund = "refund",
  reward = "reward",
}

export enum RestaurantCategory {
  American = "American",
  Arabian = "Arabian",
  Australian = "Australian",
  Chinese = "Chinese",
  French = "French",
  Indian = "Indian",
  Italian = "Italian",
  Japanese = "Japanese",
  Korean = "Korean",
  Lebanese = "Lebanese",
  Malaysian = "Malaysian",
  Mediterranean = "Mediterranean",
  Mexican = "Mexican",
  Other = "Other",
  Persian = "Persian",
  Portuguese = "Portuguese",
  Thai = "Thai",
  Turish = "Turish",
  Vietnamese = "Vietnamese",
}

export enum RestaurantType {
  Bakery = "Bakery",
  Beverages = "Beverages",
  BreakfastAndBrunch = "BreakfastAndBrunch",
  BubbleTea = "BubbleTea",
  Buffet = "Buffet",
  Cafe = "Cafe",
  CasualDining = "CasualDining",
  Coffee = "Coffee",
  Desserts = "Desserts",
  FamilyStyle = "FamilyStyle",
  FastFood = "FastFood",
  FineDining = "FineDining",
  FishChips = "FishChips",
  Halal = "Halal",
  IceCreamAndFrozenYoghurt = "IceCreamAndFrozenYoghurt",
  JuiceAndSmoothies = "JuiceAndSmoothies",
  Other = "Other",
  Seafood = "Seafood",
  Steakhouse = "Steakhouse",
  Vegetarian = "Vegetarian",
}

export enum UserPermission {
  adminDashboard = "adminDashboard",
  adsManagement = "adsManagement",
  bookingManagement = "bookingManagement",
  giftcardManagement = "giftcardManagement",
  menuManagement = "menuManagement",
  merchantDashboard = "merchantDashboard",
  merchantManagement = "merchantManagement",
  orderManagement = "orderManagement",
  promotionManagement = "promotionManagement",
  userManagement = "userManagement",
}

export enum UserRole {
  admin = "admin",
  giftCardAdmin = "giftCardAdmin",
  marketingAdmin = "marketingAdmin",
  menuAdmin = "menuAdmin",
  merchantAdmin = "merchantAdmin",
  merchantGroupAdmin = "merchantGroupAdmin",
  merchantManager = "merchantManager",
}

export enum Weekday {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export interface AdjustGiftCardInput {
  giftCardAccountId: string;
  amount: number;
  type: GiftCardAdjustmentType;
  adjustmentType: GiftCardAccountAdjustmentType;
  description?: string | null;
}

export interface AppSectionInput {
  contentType: AppContentType;
  viewType: AppViewType;
  sort: number;
  location: AppContentLocation;
  merchantIds?: (string | null)[] | null;
  items?: (string | null)[] | null;
  title: string;
  description?: string | null;
  images?: string | null;
  status: ContentStatus;
  radiumInKm?: number | null;
  coordinate?: GeolocationInput | null;
}

export interface BookingSessionInput {
  name: string;
  description?: string | null;
  guestCountLimit?: number | null;
  OpenTime: OpenTimeInput;
  hideEndTime: boolean;
  hideTableNumber: boolean;
  needDeposite: boolean;
  isEnabled: boolean;
  bookingTables?: (BookingTableInput | null)[] | null;
}

export interface BookingTableInput {
  minimumNumberOfGuests: number;
  maximumNumberOfGuests: number;
  tableNumber: string;
}

export interface BuyNGetNFreePromotionInput {
  name: string;
  description?: string | null;
  buyN: number;
  freeN: number;
  includedGetItemsPromoTags?: (string | null)[] | null;
  includedPromotionTags?: (string | null)[] | null;
  excludedPromotionTags?: (string | null)[] | null;
  numberOfDeals: number;
  availableType?: (OrderType | null)[] | null;
  minimumAmount?: number | null;
  percentage?: number | null;
  discountAmount?: number | null;
  isPercentage?: boolean | null;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
  isPublished?: boolean | null;
  canBeUsedWithOtherPromotions: boolean;
  applyOptionDiscount?: boolean | null;
  isOffer?: boolean | null;
  offerVoucherSettings?: OfferVoucherSettingsInput | null;
  upToAmount?: number | null;
  useType: PromotionUseType;
  isSignUpPromotion?: boolean | null;
  isHiddenInApp?: boolean | null;
  isPaymentRequired?: boolean | null;
  isLoyalty?: boolean | null;
}

export interface EffectiveDateTimeInput {
  startDate: any;
  endDate: any;
  recurringWeekDays?: (Weekday | null)[] | null;
  hours?: (EffectiveHoursInput | null)[] | null;
}

export interface EffectiveHoursInput {
  openStart: number;
  openEnd: number;
}

export interface GeolocationInput {
  longitude: number;
  latitude: number;
}

export interface GiftCardAccountInput {
  adminUserIds?: string[] | null;
  name: string;
  description?: string | null;
}

export interface GiftCardInput {
  recipientName: string;
  recipientPhone?: string | null;
  recipientEmail: string;
  message: string;
  description?: string | null;
  amount: number;
  sender?: string | null;
}

export interface LoyaltyBenefitInput {
  id: string;
  name: string;
  description: string;
  requiredPoints: number;
  benefitPromotionIds: string[];
}

export interface LoyaltyPromotionInput {
  name: string;
  description?: string | null;
  numberOfDeals: number;
  merchantId: string;
  useType: PromotionUseType;
  includedPromotionTags?: (string | null)[] | null;
  excludedPromotionTags?: (string | null)[] | null;
  canBeUsedWithOtherPromotions?: boolean | null;
  isHiddenInApp?: boolean | null;
  isLoyalty?: boolean | null;
  isPublished?: boolean | null;
  loyaltySettings?: LoyaltySettingsInput | null;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
}

export interface LoyaltySettingsInput {
  pointsPerDollar: number;
  maxPointsPerTransaction: number;
  expiryDays: number;
  loyaltyBenefits: LoyaltyBenefitInput[];
}

export interface LuckyDrawPromotionInput {
  name: string;
  description?: string | null;
  numberOfDeals: number;
  availableType?: (OrderType | null)[] | null;
  minimumAmount?: number | null;
  canBeUsedWithOtherPromotions?: boolean | null;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
  isPublished?: boolean | null;
  prizeDescription?: string | null;
  useType: PromotionUseType;
  isSignUpPromotion?: boolean | null;
  winnerCount: number;
  entryLimitPerUser: number;
  isEntryAutomatic?: boolean | null;
  resultingPromotions: string[];
  isHiddenInApp?: boolean | null;
  isPaymentRequired?: boolean | null;
  winCountLimitPerPerson?: number | null;
  isLoyalty?: boolean | null;
}

export interface MenuCategoryInput {
  name: string;
  altName?: string | null;
  active_begin?: number | null;
  description?: string | null;
  active_end?: number | null;
  hidden_until?: string | null;
  active: boolean;
  menuId: string;
  cookingPriority?: number | null;
  sort: number;
  promotionTags?: (string | null)[] | null;
}

export interface MenuGroupInput {
  name: string;
  allow_quantity: boolean;
  force_max: number;
  force_min: number;
  menuId: string;
  sort: number;
}

export interface MenuInput {
  name: string;
  description: string;
  type: OrderType;
  promotionTags?: (string | null)[] | null;
}

export interface MenuItemGroupsInput {
  id: string;
  sort: number;
}

export interface MenuItemInput {
  id: string;
  tags?: (string | null)[] | null;
  price: number;
  active: boolean;
  description: string;
  menuId: string;
  hidden_until?: string | null;
  sort: number;
  name: string;
  active_end?: number | null;
  groups?: (MenuItemGroupsInput | null)[] | null;
  displayNumber?: string | null;
  altName?: string | null;
  ingredients?: string | null;
  active_begin?: number | null;
  cookingCategory?: string | null;
  categoryId: string;
  promotionTags?: (string | null)[] | null;
}

export interface MenuOptionInput {
  id: string;
  name: string;
  shortName?: string | null;
  default: boolean;
  groupId: string;
  menuId: string;
  price: number;
  sort: number;
}

export interface MerchantInput {
  merchantId: string;
  name: string;
  altName?: string | null;
  address: string;
  suburb: string;
  city: string;
  phoneNumber: string;
  contactEmail?: string | null;
  abn: string;
  latitude: number;
  longitude: number;
  timezone: string;
  merchantAdminEmail: string;
  type: MerchantType;
  restaurantType?: RestaurantType | null;
  restaurantCategory?: RestaurantCategory | null;
  tags?: (string | null)[] | null;
  serviceMonthlyPrice?: number | null;
  orderbuddyMerchantId?: string | null;
  bookingMerchantId?: string | null;
  merchantStatus: MerchantStatus;
}

export interface MerchantSetingsInput {
  isStripePaymentEnabled: boolean;
  stripeAccountId?: string | null;
  maxPromotions: number;
  appServices?: (AppService | null)[] | null;
  areSoldOutItemsDisplayedInApp?: boolean | null;
}

export interface OfferVoucherSettingsInput {
  offerName: string;
  offerDescription?: string | null;
  effectiveHoursAfterOfferActivated: number;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
  offerExcludedItemTags?: (string | null)[] | null;
  minimumAmount?: number | null;
  upToAmount?: number | null;
  voucherTerms: string;
}

export interface OpenHoursInput {
  openStart: number;
  openEnd: number;
  weekDays: (Weekday | null)[];
}

export interface OpenTimeInput {
  closeDates?: (string | null)[] | null;
  openHours: (OpenHoursInput | null)[];
}

export interface PercentagePromotionInput {
  name: string;
  description?: string | null;
  percentage: number;
  discountAmount: number;
  isPercentage: boolean;
  excludedPromotionTags?: (string | null)[] | null;
  includedPromotionTags?: (string | null)[] | null;
  includedGetItemsPromoTags?: (string | null)[] | null;
  numberOfDeals: number;
  availableType?: (OrderType | null)[] | null;
  minimumAmount?: number | null;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
  isPublished?: boolean | null;
  canBeUsedWithOtherPromotions: boolean;
  applyOptionDiscount?: boolean | null;
  isSignUpPromotion?: boolean | null;
  isOffer?: boolean | null;
  offerVoucherSettings?: OfferVoucherSettingsInput | null;
  upToAmount?: number | null;
  useType: PromotionUseType;
  isHiddenInApp?: boolean | null;
  isPaymentRequired?: boolean | null;
  isLoyalty?: boolean | null;
}

export interface SpendPromotionInput {
  name: string;
  description?: string | null;
  percentage: number;
  discountAmount: number;
  isPercentage: boolean;
  excludedPromotionTags?: (string | null)[] | null;
  includedPromotionTags?: (string | null)[] | null;
  includedGetItemsPromoTags?: (string | null)[] | null;
  numberOfDeals: number;
  availableType?: (OrderType | null)[] | null;
  minimumAmount: number;
  effectiveDateTime: (EffectiveDateTimeInput | null)[];
  isPublished?: boolean | null;
  canBeUsedWithOtherPromotions: boolean;
  discountedItemQty: number;
  applyOptionDiscount: boolean;
  isSignUpPromotion?: boolean | null;
  isOffer?: boolean | null;
  offerVoucherSettings?: OfferVoucherSettingsInput | null;
  upToAmount?: number | null;
  useType: PromotionUseType;
  isHiddenInApp?: boolean | null;
  isPaymentRequired?: boolean | null;
  isLoyalty?: boolean | null;
}

export interface SurchargeInput {
  id: string;
  name: string;
  amount: number;
  isEnabled: boolean;
  isPercentage: boolean;
  publicHolidayDays?: (string | null)[] | null;
  weekDays?: (Weekday | null)[] | null;
  surchargeDescription?: string | null;
}

export interface UserInput {
  id: string;
  name: string;
  phoneNumber?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
