import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties, useCallback } from 'react';

interface promoWinnerCountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoWinnerCount: React.FC<promoWinnerCountProps> = (props) => {
  const { formik, sx } = props;
  const { setFieldValue, handleBlur, touched, errors, values } = formik;
  const { winnerCount } = values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleWinnerCountOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setFieldValue('winnerCount', value);
    },
    [setFieldValue]
  );

  return (
    <FormControl sx={defaultFormControlStyles}>
      <TextField
        placeholder={t('Total Global Win Count For This Lucky Draw')}
        type="number"
        name="winnerCount"
        value={winnerCount}
        onChange={handleWinnerCountOnChange}
        onBlur={handleBlur}
        error={Boolean(touched.winnerCount && errors.winnerCount)}
        helperText={touched.winnerCount && errors.winnerCount && t(`${errors.winnerCount}`)}
      />
    </FormControl>
  );
};
