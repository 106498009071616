import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties, SyntheticEvent } from 'react';

interface promoIsPaymentRequiredProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoIsPaymentRequired: React.FC<promoIsPaymentRequiredProps> = (props) => {
  const { formik, sx = {} } = props;
  const { isPaymentRequired } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleIsPaymentRequiredOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    formik.setFieldValue('isPaymentRequired', checked);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Payment First')}</Label>
        <FormControlLabel
          value={isPaymentRequired}
          checked={isPaymentRequired}
          onChange={handleIsPaymentRequiredOnChange}
          control={<Checkbox />}
          label={<Typography variant="body1">{t('Entry requires pre-payment')}</Typography>}
          labelPlacement="end"
        />
      </FormControl>
    </>
  );
};
