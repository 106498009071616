import { GiftCardAccount } from '@type/giftCard';
import { User } from '@type/user';
import { createGiftCardsVariables } from '__generated__/createGiftCards';
import {
  AdjustGiftCardInput,
  GiftCardAccountAdjustmentType,
  GiftCardAccountInput,
  GiftCardAdjustmentType,
  GiftCardInput,
  UserRole
} from '__generated__/globalTypes';
import Decimal from 'decimal.js';
import _ from 'lodash';

export const MAX_GIFT_CARD_ADDED_COUNT = 20;

export const createEmptyGiftCardUserInput = (): GiftCardAccountInput => {
  const temp: GiftCardAccountInput = {
    adminUserIds: [],
    name: '',
    description: ''
  };
  return temp;
};

export const createEmptyGiftCardAccount = (): GiftCardAccount => {
  return {
    id: '',
    name: 'default',
    description: '',
    adminUserIds: [],
    activeGiftCards: [],
    redeemedGiftCards: [],
    voidedGiftCards: [],
    balance: 0,
    createdAt: 0,
    updatedAt: 0,
    updatedUserId: '',
    adjustmentRecords: []
  };
};

export const createAdjustGiftCardInput = (id: string): AdjustGiftCardInput => {
  return {
    giftCardAccountId: id,
    amount: 0,
    type: GiftCardAdjustmentType.credit,
    description: '',
    adjustmentType: GiftCardAccountAdjustmentType.topUp
  };
};

export const createGiftCardInput = (sender: string): GiftCardInput => {
  return {
    recipientName: '',
    recipientPhone: '',
    recipientEmail: '',
    message: '',
    description: '',
    amount: 0,
    sender: sender
  };
};

export const createGiftCardsInput = (sender: string): createGiftCardsVariables => {
  return {
    input: [createGiftCardInput(sender)]
  };
};

export const formatToCents = (dollarsValue: string): number => {
  return new Decimal(dollarsValue).mul(100).toDecimalPlaces(0).toNumber();
};

export const sortList = <T>(list: T[], order: 'asc' | 'desc', orderBy: keyof T) => {
  let copyList = [...list];
  return _.orderBy(copyList, [orderBy], [order]);
};

export const isGiftCardAdmin = (giftCardAccount: GiftCardAccount, userId: string): boolean => {
  return giftCardAccount.adminUserIds?.includes(userId) ?? false;
};

export const GetAccountName = (userList: User[], userId: string) => {
  const user = userList.find((user) => user.id === userId);
  if (!user) return userId;
  return user.name + '/' + user.email;
};

export const checkCreateGiftCardsAccountOnBalance = (
  balance: number,
  input: GiftCardInput[]
): boolean => {
  // for loop input
  let amount = 0;
  for (let i = 0; i < input.length; i++) {
    if (!amountCheck(input[i].amount)) return false;
    // add amount to total
    amount += formatToCents(input[i].amount.toString());
  }
  // check total amount
  if (balance < amount) return false;
  return true;
};

export const calculateRemainingBalance = (balance: number, input: GiftCardInput[]): number => {
  // for loop input
  let amount = 0;
  for (let i = 0; i < input.length; i++) {
    if (!amountCheck(input[i].amount)) continue;
    // add amount to total
    amount += formatToCents(input[i].amount.toString());
  }
  return balance - amount;
};

export const convertToCreateGiftCardsAccountVariables = (
  input: GiftCardInput[]
): createGiftCardsVariables => {
  // for loop input
  // convert amount to number
  // make an copy
  const copy = _.cloneDeep(input);
  for (let i = 0; i < copy.length; i++) {
    copy[i].amount = formatToCents(copy[i].amount.toString());
  }
  return {
    input: copy
  };
};

export const amountCheck = (amount: string | number): boolean => {
  if (isNaN(Number(amount))) return false;
  if (Number(amount) <= 0) return false;
  if (amount.toString().split('.')[1]?.length > 2) return false;
  return true;
};

export const checkUserIsGIftCardAdmin = (userList: User[], userIds: string[]): boolean => {
  // check each user in userIds must exist in userList
  for (let i = 0; i < userIds.length; i++) {
    const user = userList.find((user) => user.id === userIds[i]);
    if (!user) return false;
    // userRole can be giftCardAdmin or admin
    if (user.role !== UserRole.giftCardAdmin && user.role !== UserRole.admin) return false;
  }
  return true;
};

export const isGiftCardAccountNameAssigned = (
  accounts: GiftCardAccount[],
  name: string
): boolean => {
  const isNameTaken = accounts.some((account) => account.name === name);
  return isNameTaken;
};

export const isAdminNameAssigned = (userList: string[], adminIds: string[]): boolean => {
  const areAdminsValid = adminIds.every((adminId) => userList.some((user) => user === adminId));
  return areAdminsValid;
};

export const formatCurrency = (
  amount: number,
  isCents: boolean = true,
  currency: string = 'AUD'
): string => {
  const locale: string = navigator.language || 'en-US';

  const value: number = isCents ? amount / 100 : amount;

  const formatter: Intl.NumberFormat = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency
  });

  return formatter.format(value);
};
