import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';

interface percentageSelectorProp {
  formik: FormikProps<Promotion>;
  isOffer: boolean;
}

export const PercentageSelector: React.FC<percentageSelectorProp> = (props) => {
  const { formik, isOffer } = props;

  const handleIsPercentageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === 'true';
    if (!value) {
      formik.setFieldValue('upToAmount', 0);
    }
    formik.setFieldValue('isPercentage', value);
  };
  const radioStyle = isOffer ? { color: '#DCC179' } : {};
  return (
    <>
      <FormControl>
        <RadioGroup
          row
          name="type"
          value={formik.values.isPercentage}
          onChange={handleIsPercentageOnChange}
        >
          <FormControlLabel
            value={true}
            checked={formik.values.isPercentage}
            control={<Radio style={radioStyle} />}
            label="Percentage %"
          />
          <FormControlLabel
            value={false}
            checked={!formik.values.isPercentage}
            control={<Radio style={radioStyle} />}
            label="Amount $"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};
