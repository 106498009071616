import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface promoMinimumAmountProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoOfferMinimumAmount: React.FC<promoMinimumAmountProps> = (props) => {
  const { formik, sx = {} } = props;
  const offerVoucherSettings = formik.values.offerVoucherSettings;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleMinimumAmountOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // make sure the value is a number
    if (isNaN(Number(e.target.value))) {
      return;
    }
    const numberValue = Number(e.target.value);
    formik.setFieldValue('offerVoucherSettings.minimumAmount', numberValue);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>
          {t('Minimum Order Amount')}
          <Tooltip title={t('The minimum amount your order must reach to qualify for this offer.')}>
            <IconButton>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Label>
        <TextField
          placeholder={t('Minimum Amount?')}
          type="number"
          name="minimumAmount"
          value={offerVoucherSettings?.minimumAmount}
          onChange={handleMinimumAmountOnChange}
        />
      </FormControl>
    </>
  );
};
