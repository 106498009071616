import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Promotion } from '@type/promotion';
import { FormikProps } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { PromotionType } from '__generated__/globalTypes';

interface IQtyRestrictedProps {
  formik: FormikProps<Promotion>;
  isOffer: boolean;
}

export const useQtyRestricted = (props: IQtyRestrictedProps) => {
  const { t } = useTranslation();
  const { formik } = props;
  const { type } = formik.values;
  const [isSetGetItemsCountRadioChecked, setIsSetGetItemsCountRadioChecked] = useState(
    formik.values.freeN !== 'Infinite'
  );

  const handleSetGetItemsCountRadioChanged = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (type === PromotionType.spend) {
      if (value === 'true') {
        formik.setFieldValue('discountedItemQty', 1);
        setIsSetGetItemsCountRadioChecked(true);
      } else {
        formik.setFieldValue('discountedItemQty', 'Infinite');
        setIsSetGetItemsCountRadioChecked(false);
      }
    }
    if (type === PromotionType.buynGetFree) {
      if (value === 'true') {
        formik.setFieldValue('freeN', 1);
        setIsSetGetItemsCountRadioChecked(true);
      } else {
        formik.setFieldValue('freeN', 'Infinite');
        setIsSetGetItemsCountRadioChecked(false);
      }
    }
  };

  useEffect(() => {
    // if freeN is 'Infinite' then set radio to false
    if (formik.values.freeN === 'Infinite') {
      setIsSetGetItemsCountRadioChecked(false);
    }
  }, [formik.values.freeN]);

  const qtyRestrictedRadioGroup = (
    <RadioGroup
      row
      value={isSetGetItemsCountRadioChecked}
      onChange={handleSetGetItemsCountRadioChanged}
    >
      <FormControlLabel
        value={true}
        control={<Radio />}
        label={t('X qty')}
        checked={isSetGetItemsCountRadioChecked}
      />
      <FormControlLabel
        value={false}
        control={<Radio />}
        label={t('Not qty restricted')}
        checked={!isSetGetItemsCountRadioChecked}
      />
    </RadioGroup>
  );

  return { qtyRestrictedRadioGroup };
};
