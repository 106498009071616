import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
import { Card, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { Promotion } from '../../../../@type/promotion';

// import DatePicker from '@mui/lab/DatePicker';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import { NavigateFunction, useParams } from 'react-router';
import {
  createListValidation,
  createStrNumberValidation,
  createStringValidation
} from 'utils/regexConfig';
import { useTranslation } from 'react-i18next';
import { FLOAT } from '@type/global';
import LoadingComponent from 'components/LoadingComponent';
import {
  convertPromoInput,
  getInitialPromotionState,
  getQueryByPromoType,
  removeTypenameKey
} from '../tools';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { PromotionType } from '__generated__/globalTypes';
import { usePromoStepper } from './stepper/stepper';
import { StepLabel, promoSteps } from './stepper/steps';
import { VoucherBaseInfo } from './tabs/voucher/baseInfo';
import { VoucherDetails } from './tabs/voucher/details';
import { VoucherConditions } from './tabs/voucher/conditions';
import { Summary } from './tabs/voucher/summary';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

export const yupCustomRequired = (msg: string) => Yup.number().required(msg);

export const onRedirectDiscountCompleted = (navigate: NavigateFunction) => {
  navigate(PATH_DASHBOARD.promotion.list);
};

export default function CreatePromotionForm() {
  const { showSnackbar } = useSnackbarHelper();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { promoType } = useParams<{ promoType: PromotionType }>();
  const params = useParams();
  // get user form redux
  const user = useSelector((state: RootState) => state.auth.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isEdit = params.name ? true : false;
  const [promotion] = useState<Promotion>(
    getInitialPromotionState(promoType, false, user?.merchantId)
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: promotion,
    validationSchema: Yup.object({
      name: createStringValidation({ isRequired: true }),
      // minimumAmount is required,FLOAT, equal or greater than 0
      minimumAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      // numberOfDeals is required, integer,greater than 0
      numberOfDeals: createStrNumberValidation({}),
      // percentage is required, FLOAT,greater than 0
      percentage: createStrNumberValidation({ type: FLOAT }),
      // discountAmount is required, FLOAT,greater than 0
      discountAmount: createStrNumberValidation({ type: FLOAT }),
      upToAmount: createStrNumberValidation({ type: FLOAT, isGreaterThanZero: false }),
      discountedItemQty: createStrNumberValidation({}),
      buyN: createStrNumberValidation({}),
      freeN: createStrNumberValidation({}),
      winnerCount: createStrNumberValidation({ isRequired: true }),
      entryLimitPerUser: createStrNumberValidation({ isRequired: true }),
      winCountLimitPerPerson: createStrNumberValidation({ isRequired: true }),
      resultingPromotions: createListValidation({ isRequired: true }),
      loyaltySettings: Yup.object().shape({
        pointsPerDollar: createStrNumberValidation({ type: FLOAT }),
        maxPointsPerTransaction: createStrNumberValidation({ isGreaterThanZero: false }),
        expiryDays: createStrNumberValidation({}),
        loyaltyBenefits: Yup.array()
          .of(
            Yup.object().shape({
              name: createStringValidation({ isRequired: true }),
              description: createStringValidation({ isRequired: true }),
              requiredPoints: createStrNumberValidation({ isGreaterThanZero: true }),
              benefitPromotionIds: Yup.array()
                .of(createStringValidation({ isRequired: true }))
                .min(1, 'At least one promotion ID is required')
            })
          )
          .min(1, 'At least one loyalty benefit is required')
      })
    }),
    onSubmit: async (values, { setErrors }) => {
      return;
    }
  });
  const { setFieldValue, setFieldTouched } = formik;
  const { query, create, update, responseKey } = getQueryByPromoType(formik.values.type);
  const { currentStep, setCanMoveFunc, BreadcrumbsComponent, MoveToStepComponent } =
    usePromoStepper({
      steps: promoSteps,
      isSubmitting,
      action: CreateOrUpdatePromotion(isEdit),
      promotion: formik.values
    });

  const setFieldTouchedName = useCallback(
    (name: string) => {
      setFieldTouched(name, true);
    },
    [setFieldTouched]
  );

  // query data if this is edit page
  useQuery(query, {
    variables: { promotionId: params.name },
    fetchPolicy: 'network-only',
    skip: !params.name,
    onCompleted(response) {
      setIsLoading(false);
      const promotionDataResp = response?.[responseKey];
      if (!promotionDataResp) return;
      removeTypenameKey(promotionDataResp);
      const promotionData: Partial<Promotion> = {};
      (Object.keys(promotionDataResp) as Array<keyof typeof promotionDataResp>).forEach((key) => {
        if (promotionDataResp[key] === null) return;
        promotionData[key as keyof Promotion] = promotionDataResp[key];
      });
      Object.keys(promotionData).forEach((key) => {
        const promotionKey = key as keyof Promotion;
        setFieldValue(promotionKey, promotionData[promotionKey] ?? formik.values[promotionKey]);
      });
    },
    onError(error) {
      setIsLoading(false);
    }
  });

  const [createPromotionMutation] = useMutation(create, {
    onCompleted(response) {
      onRedirectDiscountCompleted(navigate);
      showSnackbar(t('Create Success'), 'success');
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: GET_ALL_PROMOTIONS }],
    onError(error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  });

  const [updatePromotionMutation] = useMutation(update, {
    onCompleted(response) {
      onRedirectDiscountCompleted(navigate);
      showSnackbar(t('Update Success'), 'success');
      setIsSubmitting(false);
    },
    refetchQueries: [{ query: GET_ALL_PROMOTIONS }],
    onError(error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  });

  function createPromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const variables = {
        promotion: {
          ...convertPromoInput(formik.values, isPublished)
        }
      };
      createPromotionMutation({ variables });
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }

  function updatePromotion(isPublished: boolean) {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      const variables = {
        promotionId: params.name,
        promotion: {
          ...convertPromoInput(formik.values, isPublished)
        }
      };
      updatePromotionMutation({ variables });
    } catch (error) {
      showSnackbar(error.message, 'error');
      setIsSubmitting(false);
    }
  }
  function CreateOrUpdatePromotion(isEdit: boolean) {
    if (isEdit) {
      return updatePromotion;
    } else {
      return createPromotion;
    }
  }

  if (isLoading && isEdit) {
    return <LoadingComponent />;
  }
  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={10} md={10}>
            <Card sx={{ p: 3 }}>
              <BreadcrumbsComponent />
              {currentStep === StepLabel.VoucherBaseInfo && (
                <VoucherBaseInfo
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherDetails && (
                <VoucherDetails
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherConditions && (
                <VoucherConditions
                  formik={formik}
                  setCanMoveFunc={setCanMoveFunc}
                  setFieldTouchedNameFunc={setFieldTouchedName}
                />
              )}
              {currentStep === StepLabel.VoucherSummary && <Summary formik={formik} />}
              <MoveToStepComponent />
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
