import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// utils
import { GiftCard, GiftCardAccount } from '@type/giftCard';
import { createEmptyGiftCardAccount } from 'components/_dashboard/giftCard/tools/tools';
import { client } from '../../index';
import {
  CREATE_GIFTCARD_ACCOUNT,
  DELETE_GIFTCARD_ACCOUNT,
  ADJUST_GIFTCARD_ACCOUNT,
  UPDATE_GIFTCARD_ADMIN_USERS,
  VOID_GIFTCARD,
  CREATE_GIFTCARDS,
  GET_ALL_GIFTCARD_ACCOUNTS_BASE_INFO,
  SWITCH_GIFTCARD_ACCOUNT,
  GET_MY_GIFTCARDS,
  GET_MY_ADJUSTMENT_RECORDS,
  GET_GIFTCARD_ACCOUNT_BASE,
  RESENT_GIFTCARD
} from '_apis_/queries/giftCard';
import {
  switchGiftCardAccount,
  switchGiftCardAccountVariables
} from '__generated__/switchGiftCardAccount';
import { voidGiftCard, voidGiftCardVariables } from '__generated__/voidGiftCard';
import { createGiftCards, createGiftCardsVariables } from '__generated__/createGiftCards';
import {
  updateGiftCardAdminUsers,
  updateGiftCardAdminUsersVariables
} from '__generated__/updateGiftCardAdminUsers';
import {
  deleteGiftCardAccount,
  deleteGiftCardAccountVariables
} from '__generated__/deleteGiftCardAccount';
import {
  createGiftCardAccount,
  createGiftCardAccountVariables
} from '__generated__/createGiftCardAccount';
import {
  adjustGiftCardAccount,
  adjustGiftCardAccountVariables
} from '__generated__/adjustGiftCardAccount';
import { keyBy, merge } from 'lodash';
import { resentGiftCard, resentGiftCardVariables } from '__generated__/resentGiftCard';
import { GiftCardStatus } from '__generated__/globalTypes';
// ----------------------------------------------------------------------
type GiftCardAccountWrapper = {
  giftCardAccount: GiftCardAccount;
  isLoading: boolean;
  error: string | null;
};

type GiftCardAccountsWrapper = {
  giftCardAccounts: GiftCardAccount[];
  isLoading: boolean;
  error: string | null;
};

type GiftCardState = {
  myGiftCardAccount: GiftCardAccountWrapper;
  // admin
  gftCardAccounts: GiftCardAccountsWrapper;
};

const initialState: GiftCardState = {
  myGiftCardAccount: {
    giftCardAccount: createEmptyGiftCardAccount(),
    isLoading: false,
    error: null
  },
  gftCardAccounts: {
    giftCardAccounts: [],
    isLoading: false,
    error: null
  }
};

const slice = createSlice({
  name: 'giftCard',
  initialState,
  reducers: {
    setMyGiftCardAccount(state, action) {
      state.myGiftCardAccount = action.payload;
    },
    setGiftCardAccounts(state, action) {
      state.gftCardAccounts = action.payload;
    },
    voidMyGiftCard(state, action) {
      // find the giftCard in activeGiftCards and move it to voidedGiftCards
      // check activeGiftCards is empy or not

      // remove the giftCard from activeGiftCards, add it to voidedGiftCards
      // make an copy
      const newGiftCard = { ...action.payload } as GiftCard;
      newGiftCard.status = GiftCardStatus.void;
      if (!state.myGiftCardAccount.giftCardAccount) return;
      const { activeGiftCards } = state.myGiftCardAccount.giftCardAccount;
      if (!activeGiftCards) return;
      // remove the giftCard from activeGiftCards
      state.myGiftCardAccount.giftCardAccount.activeGiftCards = activeGiftCards.filter(
        (card) => card.id !== newGiftCard.id
      );
      // check voidedGiftCards is empty? if empty, create a new array with the giftCard
      if (!state.myGiftCardAccount.giftCardAccount.voidedGiftCards) {
        state.myGiftCardAccount.giftCardAccount.voidedGiftCards = [newGiftCard];
      } else {
        state.myGiftCardAccount.giftCardAccount.voidedGiftCards.push(newGiftCard);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyGiftCardAccountBase.pending, (state) => {
        state.myGiftCardAccount.isLoading = true;
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyGiftCardAccountBase.fulfilled, (state, action) => {
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        state.myGiftCardAccount.giftCardAccount = {
          ...state.myGiftCardAccount.giftCardAccount,
          ...action.payload
        };
        state.myGiftCardAccount.isLoading = false;
      })
      .addCase(fetchMyGiftCardAccountBase.rejected, (state, action) => {
        state.myGiftCardAccount.isLoading = false;
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchMyGiftCards.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyGiftCards.fulfilled, (state, action) => {
        // only update activeGiftCards redeemedGiftCards voidedGiftCards
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        // overwrite activeGiftCards redeemedGiftCards voidedGiftCards
        state.myGiftCardAccount.giftCardAccount = {
          ...state.myGiftCardAccount.giftCardAccount,
          ...action.payload
        };
      })
      .addCase(fetchMyGiftCards.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchMyAdjustmentRecords.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(fetchMyAdjustmentRecords.fulfilled, (state, action) => {
        // only update adjustmentRecords
        if (!state.myGiftCardAccount.giftCardAccount) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
        if (action.payload.adjustmentRecords) {
          state.myGiftCardAccount.giftCardAccount.adjustmentRecords =
            action.payload.adjustmentRecords;
        }
      })
      .addCase(fetchMyAdjustmentRecords.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(switchGiftCardAccountMutation.pending, (state) => {
        state.myGiftCardAccount.error = null;
      })
      .addCase(switchGiftCardAccountMutation.fulfilled, (state, action) => {
        if (action.payload) {
          state.myGiftCardAccount.giftCardAccount = createEmptyGiftCardAccount();
        }
      })
      .addCase(switchGiftCardAccountMutation.rejected, (state, action) => {
        state.myGiftCardAccount.error = action.payload as string;
      })

      .addCase(fetchGiftCardAccountsBaseInfo.pending, (state) => {
        state.gftCardAccounts.isLoading = true;
        state.gftCardAccounts.error = null;
      })
      .addCase(fetchGiftCardAccountsBaseInfo.fulfilled, (state, action) => {
        state.gftCardAccounts.isLoading = false;
        // action.payload is list of giftCardAccounts.
        // forloop and overwrite giftCardAccounts by id
        // overwirte likes this {...OldData,...newData}
        state.gftCardAccounts.isLoading = false;

        const oldGiftCardAccountsById = keyBy(state.gftCardAccounts.giftCardAccounts, 'id');
        const newGiftCardAccountsById = keyBy(action.payload, 'id');

        const updatedGiftCardAccountsById = merge(
          {},
          oldGiftCardAccountsById,
          newGiftCardAccountsById
        );

        state.gftCardAccounts.giftCardAccounts = Object.values(updatedGiftCardAccountsById).filter(
          (account) => newGiftCardAccountsById[account.id]
        );
      })
      .addCase(fetchGiftCardAccountsBaseInfo.rejected, (state, action) => {
        state.gftCardAccounts.isLoading = false;
        state.gftCardAccounts.error = action.payload as string;
      });
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { voidMyGiftCard } = slice.actions;

// ----------------------------------------------------------------------

export const fetchGiftCardAccountsBaseInfo = createAsyncThunk(
  'giftCard/fetchGiftCardAccountsBaseInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_ALL_GIFTCARD_ACCOUNTS_BASE_INFO,
        fetchPolicy: 'network-only'
      });
      if (!response.data) rejectWithValue('No data returned');
      return response.data.giftCardAccounts;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMyGiftCardAccountBase = createAsyncThunk(
  'giftCard/fetchMyGiftCardAccountBase',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_GIFTCARD_ACCOUNT_BASE,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMyGiftCards = createAsyncThunk(
  'giftCard/fetchMyGiftCards',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_MY_GIFTCARDS,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMyAdjustmentRecords = createAsyncThunk(
  'giftCard/fetchMyAdjustmentRecords',
  async (_, { rejectWithValue }) => {
    try {
      const response = await client.query({
        query: GET_MY_ADJUSTMENT_RECORDS,
        fetchPolicy: 'network-only'
      });
      const getGiftCardDataResp = response.data.giftCardAccount;
      if (!getGiftCardDataResp) return;
      return getGiftCardDataResp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGiftCardAccountMutation = createAsyncThunk<
  createGiftCardAccount,
  createGiftCardAccountVariables,
  { rejectValue: string }
>('giftCard/createGiftCardAccountMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ createGiftCardAccount: createGiftCardAccount }>({
      mutation: CREATE_GIFTCARD_ACCOUNT,
      variables: input
    });
    if (data) {
      return data.createGiftCardAccount;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteGiftCardAccountMutation = createAsyncThunk<
  deleteGiftCardAccount,
  deleteGiftCardAccountVariables,
  { rejectValue: string }
>('giftCard/deleteGiftCardAccountMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ deleteGiftCardAccount: deleteGiftCardAccount }>({
      mutation: DELETE_GIFTCARD_ACCOUNT,
      variables: input
    });
    if (data) {
      return data.deleteGiftCardAccount;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateGiftCardAdminUsersMutation = createAsyncThunk<
  updateGiftCardAdminUsers,
  updateGiftCardAdminUsersVariables,
  { rejectValue: string }
>('giftCard/updateGiftCardAdminUsersMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ updateGiftCardAdminUsers: updateGiftCardAdminUsers }>({
      mutation: UPDATE_GIFTCARD_ADMIN_USERS,
      variables: input
    });
    if (data) {
      return data.updateGiftCardAdminUsers;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const adjustGiftCardAccountMutation = createAsyncThunk<
  adjustGiftCardAccount,
  adjustGiftCardAccountVariables,
  { rejectValue: string }
>('giftCard/adjustGiftCardAccountMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ adjustGiftCardAccount: adjustGiftCardAccount }>({
      mutation: ADJUST_GIFTCARD_ACCOUNT,
      variables: input
    });
    if (data) {
      return data.adjustGiftCardAccount;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createGiftCardsMutation = createAsyncThunk<
  createGiftCards,
  createGiftCardsVariables,
  { rejectValue: string }
>('giftCard/createGiftCardsMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ createGiftCards: createGiftCards }>({
      mutation: CREATE_GIFTCARDS,
      variables: input
    });
    if (data) {
      return data.createGiftCards;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const voidGiftCardMutation = createAsyncThunk<
  voidGiftCard,
  voidGiftCardVariables,
  { rejectValue: string }
>('giftCard/voidGiftCardMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ voidGiftCard: voidGiftCard }>({
      mutation: VOID_GIFTCARD,
      variables: input
    });
    if (data) {
      return data.voidGiftCard;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const resentGiftCardMutation = createAsyncThunk<
  resentGiftCard,
  resentGiftCardVariables,
  { rejectValue: string }
>('giftCard/resentGiftCardMutation', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ resentGiftCard: resentGiftCard }>({
      mutation: RESENT_GIFTCARD,
      variables: input
    });
    if (data) {
      return data.resentGiftCard;
    }
    return rejectWithValue('No data returned');
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const switchGiftCardAccountMutation = createAsyncThunk<
  switchGiftCardAccount,
  switchGiftCardAccountVariables,
  { rejectValue: string }
>('giftCard/switchGiftCardAccount', async (input, { rejectWithValue }) => {
  try {
    const { data } = await client.mutate<{ switchGiftCardAccount: switchGiftCardAccount }>({
      mutation: SWITCH_GIFTCARD_ACCOUNT,
      variables: input
    });

    if (data) {
      return data.switchGiftCardAccount;
    } else {
      return rejectWithValue('No data returned');
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
