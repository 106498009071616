import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  close: Function;
  action: Function;
  message?: String;
  title: String;
  children?: React.ReactNode;
}

export default function ConfirmDialog({ open, close, action, message, title, children }: IProps) {
  const { t } = useTranslation();
  const handleClose = () => {
    close();
  };
  const handleAction = () => {
    action();
    close();
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children && children}
          <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleAction} autoFocus>
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
