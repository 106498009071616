import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoNumberOfDealsProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoNumberOfDeals: React.FC<promoNumberOfDealsProps> = (props) => {
  const { formik, sx = {} } = props;
  const { numberOfDeals } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleNumberOfDealsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('numberOfDeals', value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('How many deals would you like to publish?')}</Label>
        <TextField
          placeholder={t('Number of Deals')}
          type="text"
          name="numberOfDeals"
          value={numberOfDeals}
          onChange={handleNumberOfDealsOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.numberOfDeals && formik.errors.numberOfDeals)}
          helperText={
            formik.touched.numberOfDeals &&
            formik.errors.numberOfDeals &&
            t(`${formik.errors.numberOfDeals}`)
          }
        />
      </FormControl>
    </>
  );
};
