import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface PromoBuyNProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoBuyN: React.FC<PromoBuyNProps> = (props) => {
  const { formik } = props;
  const { buyN } = formik.values;
  const { t } = useTranslation();
  const handleBuyNOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('buyN', value);
  };
  return (
    <>
      <FormControl sx={{ width: '100%' }}>
        <Label>{t('Buy how many?')}</Label>
        <TextField
          placeholder={t('Buy Qty')}
          type="number"
          name="buyN"
          value={buyN}
          onChange={handleBuyNOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.buyN && formik.errors.buyN)}
          helperText={formik.touched.buyN && formik.errors.buyN && t(`${formik.errors.buyN}`)}
        />
      </FormControl>
    </>
  );
};
