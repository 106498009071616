import { Checkbox, FormControl, FormControlLabel, Stack } from '@mui/material';
import { Promotion } from '@type/promotion';
import { FormikProps } from 'formik';
import { SyntheticEvent, useState } from 'react';

import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { PromotionTagsSelector } from '../create/tabs/tagsSelect';
import ItemButton, { ItemButtonColor } from './itemButton';

export enum FieldPath {
  PROMOTION = 'excludedPromotionTags',
  OFFERSETTING = 'offerVoucherSettings.offerExcludedItemTags'
}
interface IExcludedPromotionTags {
  formik: FormikProps<Promotion>;
  isOffer?: boolean;
  path?: FieldPath;
}

export const useExcludedPromotionTags = (props: IExcludedPromotionTags) => {
  const { t } = useTranslation();
  const { formik, isOffer, path = FieldPath.PROMOTION } = props;
  const fieldPath =
    path === FieldPath.PROMOTION
      ? 'excludedPromotionTags'
      : 'offerVoucherSettings.offerExcludedItemTags';
  const excludedPromotionTags =
    path === FieldPath.PROMOTION
      ? formik.values.excludedPromotionTags || []
      : formik.values.offerVoucherSettings?.offerExcludedItemTags || [];
  // const fieldPath = isOffer
  //   ? 'offerVoucherSettings.offerExcludedItemTags'
  //   : 'excludedPromotionTags';
  const [isExcludeTagsChecked, setIsExcludeTagsChecked] = useState(
    excludedPromotionTags.length > 0
  );
  const checkBoxStyle = {
    color: isOffer ? '#DCC179' : 'default',
    '&.Mui-checked': {
      color: isOffer ? '#DCC179' : 'primary'
    }
  };
  const handleExcludedTagsCheckBoxOnChange = (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    // Determine the field path based on the value of 'isOffer'
    if (!checked) {
      formik.setFieldValue(fieldPath, []);
    }
    setIsExcludeTagsChecked(checked);
  };

  const handleExcludedPromotionTagsOnClick = (tag: string) => {
    // get an copy
    const tags = excludedPromotionTags;
    if (tags && tags.includes(tag)) {
      // remove tag
      const index = tags.indexOf(tag);
      tags.splice(index, 1);
      formik.setFieldValue(fieldPath, tags);
    }
  };
  const excludedPromoTagsCheckBox = (
    <FormControlLabel
      value={isExcludeTagsChecked}
      checked={isExcludeTagsChecked}
      onChange={handleExcludedTagsCheckBoxOnChange}
      control={<Checkbox sx={checkBoxStyle} />}
      label="Exclude items from promotion"
      labelPlacement="end"
    />
  );

  const excludedPromoTagsInput = isExcludeTagsChecked && (
    <FormControl sx={{ width: '100%' }}>
      <Label>{t('Inapplicable Items')}</Label>
      <PromotionTagsSelector fieldString={fieldPath} formik={formik} />
    </FormControl>
  );

  const excludedPromoTags = (
    <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
      {excludedPromotionTags &&
        excludedPromotionTags.map((tag, index) => (
          <div key={index}>
            <ItemButton
              onClick={() => handleExcludedPromotionTagsOnClick(tag)}
              content={tag}
              color={isOffer ? ItemButtonColor.OFFER : ItemButtonColor.DEFAULT}
            />
          </div>
        ))}
    </Stack>
  );

  return {
    excludedPromoTagsCheckBox,
    excludedPromoTagsInput,
    excludedPromoTags,
    isExcludeTagsChecked
  };
};
