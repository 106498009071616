import { FormikProps, getIn } from 'formik';
import { Promotion } from '@type/promotion';
import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Label } from '../styles';
import { PromoSelector } from './PromoSelector';
import { createPromoValidator, initLoyaltyBenefit } from '../tools';
import { isValidIndex } from 'utils/checkVariables';
import { useSnackbarHelper } from 'components/useSnackbarHelper';
import { PromotionType } from '__generated__/globalTypes';

interface promoLoyaltyBenefitsProps {
  formik: FormikProps<Promotion>;
}

export const PromoLoyaltyBenefits: React.FC<promoLoyaltyBenefitsProps> = (props) => {
  const { formik } = props;
  const { values, touched, errors } = formik;
  const { loyaltySettings } = values;
  const { loyaltyBenefits } = loyaltySettings;
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbarHelper();
  const handleAddBenefitOnClick = () => {
    const newBenefit = initLoyaltyBenefit();
    const newBenefits = [...loyaltyBenefits, newBenefit];
    formik.setFieldValue('loyaltySettings.loyaltyBenefits', newBenefits);
  };

  const getLoyaltyPath = (index: number, key: String) => {
    if (!isValidIndex(index, loyaltyBenefits)) {
      return '';
    }
    return `loyaltySettings.loyaltyBenefits[${index}].${key}`;
  };

  const handleBenefitNameOnChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isValidIndex(index, loyaltyBenefits)) {
        return;
      }
      const value = e.target.value;
      formik.setFieldValue(getLoyaltyPath(index, 'name'), value);
    };
  };
  const handleBenefitRequiredPointsOnChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isValidIndex(index, loyaltyBenefits)) {
        return;
      }
      const value = e.target.value;
      // nan check
      if (isNaN(Number(value))) {
        showSnackbar('Invalid number', 'error');
        return;
      }
      // convert to number
      const numValue = Number(value);
      formik.setFieldValue(getLoyaltyPath(index, 'requiredPoints'), numValue);
    };
  };

  const handleDescriptionOnChange = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isValidIndex(index, loyaltyBenefits)) {
        return;
      }
      const value = e.target.value;
      formik.setFieldValue(getLoyaltyPath(index, 'description'), value);
    };
  };

  const handleRemoveBtnOnClick = (index: number) => {
    return () => {
      if (loyaltyBenefits.length === 1) {
        formik.setFieldValue('loyaltySettings.loyaltyBenefits', [initLoyaltyBenefit()]);
        return;
      }
      if (!isValidIndex(index, loyaltyBenefits)) {
        showSnackbar('Invalid index', 'error');
        return;
      }
      const newBenefits = [...loyaltyBenefits];
      newBenefits.splice(index, 1);
      formik.setFieldValue('loyaltySettings.loyaltyBenefits', newBenefits);
    };
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <Button onClick={handleAddBenefitOnClick} variant="contained">
          Add
        </Button>
      </Box>
      {loyaltyBenefits.map((benefit, index) => {
        return (
          <Paper elevation={3} key={index} sx={{ padding: '16px' }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Label>{t('Name')}</Label>
                  <TextField
                    placeholder={t('Name?')}
                    type="text"
                    name={getLoyaltyPath(index, 'name')}
                    value={benefit.name}
                    onChange={handleBenefitNameOnChange(index)}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      getIn(touched, getLoyaltyPath(index, 'name')) &&
                        getIn(errors, getLoyaltyPath(index, 'name'))
                    )}
                    helperText={
                      getIn(touched, getLoyaltyPath(index, 'name')) &&
                      getIn(errors, getLoyaltyPath(index, 'name'))
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Label>{t('Required points')}</Label>
                  <TextField
                    placeholder={t('Need 100 points to redeem.')}
                    type="number"
                    name={getLoyaltyPath(index, 'requiredPoints')}
                    value={benefit.requiredPoints}
                    onChange={handleBenefitRequiredPointsOnChange(index)}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      getIn(touched, getLoyaltyPath(index, 'requiredPoints')) &&
                        getIn(errors, getLoyaltyPath(index, 'requiredPoints'))
                    )}
                    helperText={
                      getIn(touched, getLoyaltyPath(index, 'requiredPoints')) &&
                      getIn(errors, getLoyaltyPath(index, 'requiredPoints'))
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Label>{t('Rewards')}</Label>
                  <PromoSelector
                    formik={formik}
                    formikPath={getLoyaltyPath(index, 'benefitPromotionIds')}
                    promoValidator={createPromoValidator([
                      PromotionType.luckyDraw,
                      PromotionType.loyalty
                    ])}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <Label>{t('Description')}</Label>
                  <TextField
                    placeholder={t('Please enter the description of the voucher')}
                    type="text"
                    multiline={true}
                    name={getLoyaltyPath(index, 'description')}
                    value={benefit.description}
                    onChange={handleDescriptionOnChange(index)}
                    onBlur={formik.handleBlur}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Button
                    onClick={handleRemoveBtnOnClick(index)}
                    sx={{ marginTop: '16px' }}
                    variant="contained"
                    color="error"
                  >
                    Remove
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </>
  );
};
