// useSnackbarHelper.tsx
import React from 'react';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import { MIconButton } from 'components/@material-extend';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

interface SnackbarHelper {
  showSnackbar: (message: SnackbarMessage, variant: VariantType) => void;
}

export const useSnackbarHelper = (): SnackbarHelper => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSnackbar = (message: SnackbarMessage, variant: VariantType) => {
    const action = (key: SnackbarKey) => (
      <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        <Icon icon={closeFill} />
      </MIconButton>
    );

    const options: OptionsObject = {
      variant,
      action
    };

    enqueueSnackbar(message, options);
  };

  return { showSnackbar };
};
