import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface promoVoucherTermsProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoOfferVoucherTerms: React.FC<promoVoucherTermsProps> = (props) => {
  const { formik, sx = {} } = props;
  const offerVoucherSettings = formik.values.offerVoucherSettings;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleVoucherTermsOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('offerVoucherSettings.voucherTerms', e.target.value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>
          {t('Voucher Terms')}
          <Tooltip title={t('The terms and conditions that apply to the use of this voucher.')}>
            <IconButton>
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Label>
        <TextField
          sx={{ marginBottom: '24px' }}
          fullWidth
          multiline
          minRows={2}
          maxRows={4}
          placeholder={t('Detail the conditions under which the voucher and offer applies')}
          type="text"
          name="voucherTerms"
          value={offerVoucherSettings?.voucherTerms}
          onChange={handleVoucherTermsOnChange}
        />
      </FormControl>
    </>
  );
};
