import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { Label } from '../styles';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoNameProps {
  formik: FormikProps<Promotion>;
  isOffer?: boolean;
  sx?: CSSProperties;
}
export const PromoName: React.FC<promoNameProps> = (props) => {
  const { formik, isOffer, sx = {} } = props;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isOffer) {
      formik.setFieldValue('offerVoucherSettings.offerName', e.target.value);
      return;
    }
    formik.setFieldValue('name', e.target.value);
  };

  const getName = () => {
    if (isOffer) {
      return formik.values.offerVoucherSettings?.offerName || '';
    }
    return formik.values.name;
  };

  const getFieldName = () => {
    if (isOffer) {
      return 'offerVoucherSettings.offerName';
    }
    return 'name';
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <Label>{t('Name')}</Label>
        <TextField
          placeholder={t('Please enter the name of the voucher/offer/luckydraw')}
          type="text"
          name={getFieldName()}
          value={getName()}
          onChange={handleNameOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.name && formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name && t(`${formik.errors.name}`)}
        />
      </FormControl>
    </>
  );
};
