import { FormikProps } from 'formik';
import React from 'react';
import { Promotion } from '@type/promotion';
import { FormControl } from '@mui/material';
import { PromoSummary } from 'components/_dashboard/promotion/formik/promoSummary';

export interface summaryProps {
  formik: FormikProps<Promotion>;
}

export const Summary: React.FC<summaryProps> = (props) => {
  const { formik } = props;
  const { values } = formik;
  return (
    <FormControl sx={{ width: '100%' }}>
      <PromoSummary promotion={values} />
    </FormControl>
  );
};
