export enum StepLabel {
  VoucherBaseInfo = 'Voucher Base Info',
  VoucherDetails = 'Voucher Details',
  VoucherConditions = 'Voucher Conditions',
  VoucherSummary = 'Voucher Summary',
  OfferBaseInfo = 'Offer Base Info',
  OfferDetails = 'Offer Details',
  OfferConditions = 'Offer Condition',
  VoucherCreation = 'Voucher Creation',
  OfferSummary = 'Offer Summary',
  Summary = 'Summary'
}
export interface Step {
  label: StepLabel;
  action?: () => void;
}

export const promoSteps = [
  { label: StepLabel.VoucherBaseInfo },
  { label: StepLabel.VoucherDetails },
  { label: StepLabel.VoucherConditions },
  { label: StepLabel.VoucherSummary }
];

export const offerSteps = [
  { label: StepLabel.OfferBaseInfo },
  { label: StepLabel.OfferDetails },
  { label: StepLabel.OfferConditions },
  { label: StepLabel.VoucherBaseInfo },
  { label: StepLabel.VoucherDetails },
  { label: StepLabel.VoucherConditions },
  { label: StepLabel.OfferSummary }
];
