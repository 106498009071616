export enum AppContentType {
  FEATURED = 'Featured',
  NEW = 'New',
  RECOMMENDED = 'Recommended',
  POPULAR = 'Popular',
  CATEGORY = 'Category'
}

export enum AppContentLocation {
  HOME = 'home',
  CATEGORY = 'category',
  NOTIFICATION = 'notification',
  VOUCHER = 'voucher',
  ACCOUNT = 'account'
}

export enum AppViewType {
  VERTICAL_LIST = 'verticalList',
  HORIZONTAL_LIST = 'horizontalList',
  GRID = 'grid',
  CAROUSEL = 'carousel',
  BANNER = 'banner',
  BANNER_SLIDER = 'bannerSlider',
  BANNER_CAROUSEL = 'bannerCarousel'
}

export enum ContentStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published'
}
