import { FormControl, Grid } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Promotion } from '@type/promotion';
import { useMutipleEffectiveDateTime } from 'components/_dashboard/promotion/effectiveDateTime';
import { PromoOfferVoucherTerms } from 'components/_dashboard/promotion/formik/promoOfferVoucherTerms';
import { EffectiveDateTimeType } from 'components/_dashboard/promotion/effectiveDateTime/type/type';
import { PromoOfferUpToAmount } from 'components/_dashboard/promotion/formik/promoOfferUpToAmount';
import { PromoOfferMinimumAmount } from 'components/_dashboard/promotion/formik/promoOfferMinImumAmount';
import { canMoveProps } from '../../stepper/stepper';
import { offerVoucherConditionCheck } from 'components/_dashboard/promotion/tools';

export interface offerVoucherConditionsProps {
  formik: FormikProps<Promotion>;
  setCanMoveFunc: React.Dispatch<React.SetStateAction<() => canMoveProps>>;
}

export const OfferVoucherConditions: React.FC<offerVoucherConditionsProps> = (props) => {
  const { formik, setCanMoveFunc } = props;
  const { isPercentage } = formik.values;
  const setEffectiveDateTime = (newList: EffectiveDateTimeType[]) => {
    formik.setFieldValue('offerVoucherSettings.effectiveDateTime', newList);
  };
  const { effectiveDateTime } = useMutipleEffectiveDateTime({
    dateTimesList: formik.values.offerVoucherSettings?.effectiveDateTime || [],
    setDateTimeList: setEffectiveDateTime,
    isOffer: false
  });

  const canMove = useCallback(() => {
    let message = '';
    let canMove = true;
    try {
      offerVoucherConditionCheck(formik.values);
    } catch (e) {
      message = e.message;
      canMove = false;
    } finally {
      return {
        message: message,
        canMove: canMove
      };
    }
  }, [formik.values]);

  useEffect(() => {
    setCanMoveFunc(() => canMove);
  }, [setCanMoveFunc, canMove]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <PromoOfferMinimumAmount formik={formik} />
        </Grid>
        {isPercentage && (
          <Grid item xs={12} md={6}>
            <PromoOfferUpToAmount formik={formik} />
          </Grid>
        )}
        <Grid item xs={12} md={12}>
          <PromoOfferVoucherTerms formik={formik} />
        </Grid>
      </Grid>
      {effectiveDateTime}
    </FormControl>
  );
};
