import { FormikProps, getIn } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoMaxPointsPerTransactionProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}

export const PromoMaxPointsPerTransaction: React.FC<promoMaxPointsPerTransactionProps> = (
  props
) => {
  const { formik, sx = {} } = props;
  const { values, touched, errors } = formik;
  const { loyaltySettings } = values;

  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };

  const handleMaxPointsPerTransactionOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // number
    const numValue = Number(value);
    formik.setFieldValue('loyaltySettings.maxPointsPerTransaction', numValue);
  };

  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Earn up to 500 points per order.')}
          type="number"
          name="loyaltySettings.maxPointsPerTransaction"
          value={loyaltySettings?.maxPointsPerTransaction || ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">{'Points'}</InputAdornment>
          }}
          onChange={handleMaxPointsPerTransactionOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            getIn(touched, 'loyaltySettings.maxPointsPerTransaction') &&
              getIn(errors, 'loyaltySettings.maxPointsPerTransaction')
          )}
          helperText={
            getIn(touched, 'loyaltySettings.maxPointsPerTransaction') &&
            getIn(errors, 'loyaltySettings.maxPointsPerTransaction')
          }
        />
      </FormControl>
    </>
  );
};
