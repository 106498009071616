import { FormikProps } from 'formik';
import { Promotion } from '@type/promotion';
import { FormControl, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';

interface promoWinCountLimitPerPersonProps {
  formik: FormikProps<Promotion>;
  sx?: CSSProperties;
}
export const PromoWinCountLimitPerPerson: React.FC<promoWinCountLimitPerPersonProps> = (props) => {
  const { formik, sx = {} } = props;
  const { winCountLimitPerPerson } = formik.values;
  const { t } = useTranslation();
  const defaultFormControlStyles: CSSProperties = {
    width: '100%',
    ...sx
  };
  const handleWinCountLimitPerPersonOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    formik.setFieldValue('winCountLimitPerPerson', value);
  };
  return (
    <>
      <FormControl sx={defaultFormControlStyles}>
        <TextField
          placeholder={t('Maximum Number of Wins Allowed Per User')} ///
          type="number"
          name="winCountLimitPerPerson"
          value={winCountLimitPerPerson}
          onChange={handleWinCountLimitPerPersonOnChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched.winCountLimitPerPerson && formik.errors.winCountLimitPerPerson
          )}
          helperText={
            formik.touched.winCountLimitPerPerson &&
            formik.errors.winCountLimitPerPerson &&
            t(`${formik.errors.winCountLimitPerPerson}`)
          }
        />
      </FormControl>
    </>
  );
};
