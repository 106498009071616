import { useCallback, useEffect, useState } from 'react';
// material
import { Container, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// @types
// components
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_ALL_PROMOTIONS } from '_apis_/queries/promotion';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import { Promotion } from '@type/promotion';
import { PromotionHistoryTable } from './PromotionHistoryTable';

export default function PromotionHistory() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const [promotionList, setPromotionList] = useState<Promotion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [queryGetAllPromotions] = useLazyQuery(GET_ALL_PROMOTIONS, {
    onCompleted(response) {
      const data = response.getPromotions as Promotion[];
      const currentDate = new Date();
      const validPromotions = data.filter((promotion) => {
        return promotion.effectiveDateTime.some((dateTime) => {
          // get the start date of the promotion
          const endDate = new Date(dateTime.endDate);
          // get the history of the promotion
          return currentDate > endDate;
        });
      });
      setIsLoading(false);
      setPromotionList(validPromotions);
    },
    fetchPolicy: 'network-only',
    onError(error) {
      console.log('Get data fail: ', error);
    }
  });
  useEffect(() => {
    queryGetAllPromotions();
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryGetAllPromotions]);

  const getVoucherList = useCallback(() => {
    // return isOffer = flase
    return promotionList.filter((promotion) => promotion.isOffer === false);
  }, [promotionList]);

  const getOfferList = useCallback(() => {
    // return isOffer = true
    return promotionList.filter((promotion) => promotion.isOffer === true);
  }, [promotionList]);

  return (
    <Page title={t('Promotion History')}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={t('Promotion History')}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Promotion'), href: PATH_DASHBOARD.promotion.history },
            { name: t('History') }
          ]}
        />
        <Stack justifyContent="space-between" spacing={3} sx={{ mb: 5 }}>
          <PromotionHistoryTable
            promotionList={getVoucherList()}
            isOffer={false}
            isLoading={isLoading}
          />
          <PromotionHistoryTable
            promotionList={getOfferList()}
            isOffer={true}
            isLoading={isLoading}
          />
        </Stack>
      </Container>
    </Page>
  );
}
