import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useTranslation } from 'react-i18next';
import CreatePromotionForm from 'components/_dashboard/promotion/create/createPromotionForm';
import CreateOfferForm from 'components/_dashboard/promotion/create/createOfferForm';

// ----------------------------------------------------------------------

enum disolayForm {
  promotion = 'promotion',
  offer = 'offer'
}

export default function Promotion() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { name = '' } = useParams();
  const isOffer = pathname.includes('offer');
  const displayForm = isOffer ? disolayForm.offer : disolayForm.promotion;

  const getTitle = () => {
    // if name is not empty then it is edit mode
    // if name is empty then it is create mode
    // if isOffer is true then it is offer mode
    // title = create a new promotion\new offer
    // title = edit promotion\edit offer
    let title = '';
    if (name) {
      title = isOffer ? t('Edit offer') : t('Edit promotion');
    } else {
      title = isOffer ? t('Create a new offer') : t('Create a new promotion');
    }
    return title;
  };

  return (
    <Page title={t('Create a new promotion')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading={getTitle()}
          links={[
            { name: t('Dash Board'), href: PATH_DASHBOARD.root },
            { name: t('Promotion'), href: PATH_DASHBOARD.promotion.list },
            { name: getTitle() }
          ]}
        />
        {displayForm === disolayForm.promotion && <CreatePromotionForm />}
        {displayForm === disolayForm.offer && <CreateOfferForm />}
      </Container>
    </Page>
  );
}
